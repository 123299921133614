import { DiscountPlanName } from "@/generated/open-api/schemas";

import { SubscriptionProduct } from "../helper";

interface GetDiscountPriceParams {
  product: SubscriptionProduct;
  discountPlanName: DiscountPlanName;
  isFirstSubscriptionOrder: boolean;
  pastDiscountRate: boolean;
  isProductBrandNewDiscount: boolean;
}
export const getDiscountPrice = (params: GetDiscountPriceParams) => {
  const {
    product,
    discountPlanName,
    isFirstSubscriptionOrder,
    pastDiscountRate,
    isProductBrandNewDiscount,
  } = params;

  const productPrice = product.prices[discountPlanName];
  if (!productPrice) return 0;
  if (isProductBrandNewDiscount) {
    return Number(productPrice.product_brand_new_discount_price);
  }
  return pastDiscountRate || isFirstSubscriptionOrder
    ? Number(productPrice.first_subscription_discount_price)
    : Number(productPrice.normal_subscription_discount_price);
};
