import clsx from "clsx";

import { ImageLegacy } from "@/components/displays";

import styles from "./CustomerSupportSection.module.scss";
import { ReturnToMyPageLink } from "../ReturnToMyPageLink";

export function CustomerSupportSection(): React.ReactNode {
  return (
    <section>
      <div className="col-12 col-m-6 col-offset-m-3">
        <p className="text__xl text__bold text__center mg__bottom__m  pd__top__l">
          カスタマーサポート
          <span className="text__l">に</span>
          <br />
          いつでもご相談ください！
        </p>
        <ImageLegacy
          src="https://asset.basefood.co.jp/images/parts/illust_customer_support_ok.png"
          alt="いつでもご相談ください！"
          aspectRatio="476/317"
        />
        <p className="text__m text__left text__center__pc mg__top__m mg__bottom__m">
          お困りのことがあればカスタマーサポートへご相談ください！
        </p>
        <div className="text__center mg__bottom__m">
          <a
            href="https://basefood.zendesk.com/hc/ja/requests/new"
            className="btn inline round yellow angle__right"
            target="_blank"
            data-testid="CustomerSupportSection-contact-button"
          >
            カスタマサポートに連絡する
            <i className="fas fa-angle-right" />
          </a>
        </div>
        <ReturnToMyPageLink data-testid="CustomerSupportSection-return-button" />
      </div>
      <div className="col-12 col-m-6 col-offset-m-3 mg__bottom__l">
        <div className={clsx("bg__white mile__user__border mg__top__l", styles.qaContainer)}>
          <p className="text__l text__bold text__center mg__bottom__m">よくあるご質問</p>
          <ul>
            <li>
              <a href="https://basefood.zendesk.com/hc/ja/articles/4402493001241" target="_blank">
                お届け場所は変更できますか？
              </a>
            </li>
            <li>
              <a href="https://basefood.zendesk.com/hc/ja/articles/4404483612057" target="_blank">
                支払い方法を代引きからクレジットカードに変更できますか？
              </a>
            </li>
            <li>
              <a href="https://basefood.zendesk.com/hc/ja/articles/360029610552" target="_blank">
                コンビニ受け取りに変更できますか？
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
