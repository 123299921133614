import clsx from "clsx";

import { ImageLegacy } from "@/components/displays";

import laboBanner from "$/images/unsubscription/labo.png";
import laboBannerSp from "$/images/unsubscription/labo_sp.png";

import styles from "./BasefoodLaboSection.module.scss";
import { ReturnToMyPageLink } from "../ReturnToMyPageLink";

export function BasefoodLaboSection(): React.ReactNode {
  return (
    <section>
      <div className="col-12 col-m-10 col-offset-m-1">
        <p className="text__xl text__bold text__center pd__top__l">
          おいしいアレンジ
          <span className="text__l">を</span>
          <br />
          試してみませんか？
        </p>
        <p className="text__m text__left text__center__pc mg__top__m">
          ベースフードのオンラインコミュニティBASE FOOD Laboにて、
          <br className="clear__sp" />
          <span className="text__red text__bold">
            公式厳選おすすめレシピと全国のベースフードユーザーのレシピ
          </span>
          を発信しています！
        </p>
      </div>
      <div className="col-12 col-m-6 col-offset-m-3">
        <div
          className={clsx(
            "bg__white",
            "pd__top__m",
            "mg__top__l",
            "rounded-10px",
            "mg__bottom__m",
            styles.laboContentPadding
          )}
        >
          <ImageLegacy
            containerClassName="clear__sp"
            src={laboBanner.src}
            alt="BASE FOOD Laboをみる"
            aspectRatio="872/442"
          />
          <ImageLegacy
            containerClassName="clear__pc"
            src={laboBannerSp.src}
            alt="BASE FOOD Laboをみる"
            aspectRatio="564/815"
          />
          <div className="text__center pd__top__m pd__bottom__m">
            <a
              href="https://labo.basefood.co.jp/view/box"
              className="btn inline round yellow"
              target="_blank"
            >
              BASE FOOD Laboをみる
            </a>
          </div>
          <ImageLegacy
            src="https://asset.basefood.co.jp/images/parts/illust_labo.png"
            alt="BASE FOOD Laboをみる"
            aspectRatio="952/386"
          />
        </div>
        <ReturnToMyPageLink data-testid="BasefoodLaboSection-return-button" />
      </div>
    </section>
  );
}
