import React from "react";

import clsx from "clsx";

import { Column } from "@/components/containers";
import { ImageLegacy } from "@/components/displays";

import styles from "./GoingOut.module.scss";

export function GoingOut() {
  return (
    <Column className={styles.root}>
      <p className={clsx("text__l", "text__bold", "mg__top__m", "mg__bottom__m")}>
        オフィスランチや外出時には
        <br />
        BASE FOODがぴったり！
      </p>
      <ImageLegacy
        className={styles.img}
        aspectRatio="670 / 457"
        alt="オフィスランチ"
        src="https://asset.basefood.co.jp/images/ph/illust_workers_outline.png"
      />

      <ul className={clsx("text__left", "mg__top__m", styles.list)}>
        <li>調理不要でそのまま食べられるのでいそがしいときにおすすめ</li>
        <li>賞味期限が長く、オフィスの常備食にぴったり</li>
        <li>BASE BREADは個包装で持ち運びに便利</li>
        <li>小腹が空いた時はBASE Cookiesがおすすめ</li>
      </ul>
    </Column>
  );
}
