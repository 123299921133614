export const getIngredientImageUrl = (ingredientName: string) => {
  return "https://asset.basefood.co.jp/images/parts/food_icon/" + ingredientName + ".svg";
};

const continuousMessages = {
  1: "BASE FOODの継続コースへようこそ！まずは変化を実感するため、毎日とり入れるのがおすすめです。",
  2: "からだの細胞は、日々入れ替わっています。 まずは3ヶ月、栄養バランスのいい食事で、からだの中から変えていきましょう。",
  3: "BASE FOODは全粒粉が主原料。表皮や胚芽もまるごと含まれる全粒粉は、食物繊維やビタミンなどの栄養素が豊富です。",
  4: "健康習慣を継続する秘訣は、同じ目標に向かってがんばる仲間をつくること。SNSで手軽に仲間をさがすのもおすすめです。",
  5: "BASE FOODは、小学校の給食と同じくらいの頻度、月20食を目安に取り入れることをおすすめしています。",
  6: 'BASE BREAD チョコレートのおすすめアレンジをご紹介！ブレッドチョコとフルーツの盛り合わせは<a href="https://labo.basefood.co.jp/view/post/0/183201">こちら</a>',
  7: 'BASE BREAD ミニ食パン・プレーンのおすすめアレンジをご紹介！手軽にパクッと！「ラスク」は<a href="https://labo.basefood.co.jp/view/post/0/200044">こちら</a>',
  8: 'BASE BREAD プレーンのおすすめアレンジをご紹介！塩キャラメルバナナサンドは<a href="https://labo.basefood.co.jp/view/post/0/231679">こちら</a>',
  9: "BASE BREADの人気商品・チョコレートは、朝食にもおやつにもぴったりなマーブルパン。まだの方は一度ぜひ！",
  10: 'BASE BREAD メープルのおすすめアレンジをご紹介！メープルベースミルフィーユは<a href="https://labo.basefood.co.jp/view/post/0/146780">こちら</a>',
  11: "BASE BREAD メープルは、まろやかな甘みにほっとする、スティックタイプのパン。やわもち食感をたのしんで。",
  12: "継続コース1周年！健康習慣を継続できていますね。2年目からも、よろしくお願いします。1年間でとった栄養素をSNSでシェアしてみませんか？",
  13: "本格シナモンの豊かな香りがたのしめるシナモン味は、コーヒーと相性抜群。3時のおやつにいかがですか。",
  14: '継続コース利用者限定のコミュニティ「BASE FOOD Labo」では管理栄養士へ相談ができるコーナー「<a href="https://labo.basefood.co.jp/view/box?boxId=M_E3qS061&categoryIndex=99">BASE FOOD CAMP</a>」があります。どなたでも相談可能なのでぜひご覧ください。',
  15: "BASE Cookies ココナッツは、粗挽きココナッツの風味と香りがひとくちごとに広がるクッキー。ココナッツ好きの方はぜひ。",
  16: "食べ応え抜群のBASE BREAD カレー。揚げずに焼いてしあげた、ヘルシーでピリッとしたおいしさは、ランチにピッタリ。",
  17: "サクもち食感の全粒粉ミニ食パンはもう試しましたか？朝の時間にドタバタしがちな方に、特におすすめ。",
  18: "BASE Cookiesは、お子さまのおやつにもおすすめ。おやつからもおいしく栄養補給しませんか。",
  19: 'BASE BREAD ミニ食パン・プレーンのおすすめアレンジをご紹介！カッテージチーズとミニトマトのトーストは<a href="https://labo.basefood.co.jp/view/post/0/256830">こちら</a>',
  20: "BASE Cookies ココアは、ココアの風味とサクサク食感のバランスが良い、落ち着く大人のクッキー。朝食代わりにも便利。",
  21: "自由なアレンジをたのしみたい方におすすめなのが、BASE BREAD プレーン。ふんわり食感の全粒粉パンです。",
  22: "深みのある大人なあじわいの、BASE Cookies 抹茶。一息つきたい休憩に、上質な抹茶の風味をたのしんで。",
  23: "アールグレイの香りが引き立つBASE Cookiesアールグレイはすでに試しましたか？気分のほぐれる香りのクッキーです。",
  24: "継続コース開始から2年目突入！いつもありがとうございます。3年目もBASE FOODとともに、健康な日々を目指してくれるとうれしいです。",
  25: "いつもベースフードをご愛用いただきありがとうございます。%年目も引き続きどうぞよろしくお願いいたします。",
};

type ContinuousMessageIndex = keyof typeof continuousMessages;

export const formatContinuousMonth = (continuousMonths: number) => {
  if (continuousMonths < 12) {
    return continuousMonths + "ヶ月目";
  }
  if (continuousMonths === 12) {
    return "1年目";
  }
  if (continuousMonths < 24) {
    return "1年" + (continuousMonths - 12) + "ヶ月目";
  }
  return Math.floor(continuousMonths / 12) + "年";
};

export const getContinuousMessage = (continuousMonths: number) => {
  // 24ヶ月までは定義順にそのまま
  if (continuousMonths <= 24) {
    return continuousMessages[continuousMonths as ContinuousMessageIndex];
  }
  // 25~35ヶ月は2年目のメッセージを出す
  if (continuousMonths < 36) {
    return continuousMessages[24];
  }
  // メッセージが3年目までなので、それ以降は3年目を出しておく(一応年数だけ置き換える)
  return continuousMessages[25].replace("%", Math.floor(continuousMonths / 12).toString());
};
