import React from "react";

import clsx from "clsx";

import { Column } from "@/components/containers";
import { ImageLegacy } from "@/components/displays";
import { Button } from "@/components/inputs";

import styles from "./Substitute.module.scss";
import { RetentionContentProps } from "../common";

export function Substitute({ onAction }: RetentionContentProps) {
  return (
    <Column className={styles.root}>
      <p className={clsx("text__xl", "text__center", "text__bold", "mg__top__m", "mg__bottom__m")}>
        <span className={clsx("bg__white", "text__red", styles.markpen)}>33種類</span>
        の栄養素が
        <br />
        ギュッと1食に！
      </p>
      <p className={clsx("text__m", "text__center")}>
        健康や予防を目的とした食事では
        <br />
        <span className={clsx("text__l", "text__red", "text__bold")}>「栄養バランス」</span>
        が最も大事！
      </p>
      <div className={clsx("bg__white", styles.bg, "mg__top__m", "mg__bottom__m")}>
        <p className={clsx("text__m", "text__bold", "mg__bottom__xs")}>
          BASE BREAD®に含まれる栄養素
        </p>
        <p className={clsx("text__s", "mg__bottom__m")}>1食あたりの推奨摂取量との各栄養素比較</p>
        <ImageLegacy
          className={styles.image}
          containerClassName={styles.imageContainer}
          aspectRatio="49 / 109"
          alt="グラフ"
          src="https://asset.basefood.co.jp/images/graph/graph_bb_plain_v3_10_v.png"
        />
        <p className={clsx("text__s", "text__gray__dark", "text__left", "mg__top__m")}>
          ※推定値
          ※栄養素等表示基準値（18才以上、基準熱量2,200kcal）に基づき、1日分の基準値の1/3を100とした場合
          ※プレーン1食2袋の場合
        </p>
      </div>
      <p className={clsx("text__m", "text__left")}>
        <span className={clsx("text__red", "text__bold")}>すべての栄養素の基準を満たすこと</span>
        が健康なからだづくりには欠かせません。ベースフードを生活に取り入れて、1日の栄養バランスを整えましょう！
      </p>
      <Button variants="yellow" rounded onClick={() => onAction({ type: "cancel" })}>
        スキップせずに続ける
      </Button>
    </Column>
  );
}
