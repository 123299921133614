const storeKey = "checkout.survey.order_email";

export function getOrderEmail(): string | null {
  return sessionStorage.getItem(storeKey);
}

export function saveOrderEmail(email: string) {
  sessionStorage.setItem(storeKey, email);
}

export function clearOrderEmail() {
  sessionStorage.removeItem(storeKey);
}
