import { subscriptionProductAnnotation } from "@/configs/products";
import { CartCalcApiResponse } from "@/generated/open-api/schemas";
import { CartModel } from "@/models/cart/type";
import {
  breadCategoryProductNames,
  cookieCategoryProductNames,
  deliPastaCategoryProductNames,
  deliYakisobaCategoryProductNames,
  pancakeCategoryProductNames,
  pastaCategoryProductNames,
  sauceCategoryProductNames,
} from "@/models/product/consts";
import { ProductModel } from "@/models/product/type";
import { CustomerDiscountPlanModel, SubscriptionModel } from "@/models/subscription/type";
import { SnakeToCamelCaseNested } from "@/utils";

import {
  newProductNames,
  limitedProductNames,
  subscriptionProductCautions,
  subscriptionProductDescriptions,
} from "./consts";

export const orderDeliveryStatusColors = {
  None: "yellow",
  PartlyShipped: "green",
  Shipped: "green",
  Cancel: "red",
} as const;

export type Category = "bread" | "cookie" | "pasta" | "pancake" | "deliYakisoba" | "deliPasta";

export const getProductCategory = <T extends { name: string }>(product: T): Category => {
  if ((breadCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
    return "bread";
  } else if ((cookieCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
    return "cookie";
  } else if (
    (pastaCategoryProductNames as ReadonlyArray<string>).includes(product.name) ||
    (sauceCategoryProductNames as ReadonlyArray<string>).includes(product.name)
  ) {
    // 焼きそば、ソースセットはパスタに分類される
    return "pasta";
  } else if ((pancakeCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
    return "pancake";
  } else if ((deliPastaCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
    // これは冷凍のやつ
    return "deliPasta";
  } else if ((deliYakisobaCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
    // カップ麺
    return "deliYakisoba";
  }
  throw new Error(`Invalid product name: ${product.name}`);
};

export const groupProductsByCategory = <T extends { name: string }>(products: T[]) => {
  return products.reduce(
    (acc, product) => {
      const category = getProductCategory(product);
      acc[category].push(product);
      return acc;
    },
    {
      bread: [] as T[],
      cookie: [] as T[],
      pasta: [] as T[],
      pancake: [] as T[],
      deliPasta: [] as T[],
      deliYakisoba: [] as T[],
    }
  );
};

interface ConvertSubscriptionProductParams {
  product: ProductModel;
  defaultProduct?: ProductModel;
  brandNewDiscounts: SnakeToCamelCaseNested<
    Required<CartCalcApiResponse>["cart"]
  >["productBrandNewDiscounts"];
}

export function convertSubscriptionProduct({
  product,
  defaultProduct,
  brandNewDiscounts,
}: ConvertSubscriptionProductParams) {
  return {
    ...product,
    isNew: newProductNames.includes(product.name),
    isLimited: limitedProductNames.includes(product.name),
    description:
      subscriptionProductDescriptions.find((description) => description.sku === product.sku)
        ?.description ?? "",
    brandNewDiscount:
      brandNewDiscounts?.find(
        (productBrandNewDiscount) => productBrandNewDiscount.variantId === product.variantId
      ) ?? null,
    cautions:
      subscriptionProductCautions.find((caution) => caution.sku === product.sku)?.cautions ?? [],
    annotation:
      subscriptionProductAnnotation.find((annotation) => annotation.sku === product.sku)
        ?.annotation ?? [],
    isChanged: (defaultProduct?.quantity ?? 0) !== product.quantity,
  };
}

export type SubscriptionProduct = ReturnType<typeof convertSubscriptionProduct>;

interface GetDiscountRateParams {
  discountPlan: CustomerDiscountPlanModel | null | undefined;
  cart: CartModel | null | undefined;
  subscription: SubscriptionModel | null | undefined;
}
export const getDiscountRate = (params: GetDiscountRateParams) => {
  const { discountPlan, cart, subscription } = params;
  if (discountPlan) {
    if (cart?.isFirstSubscriptionOrder) {
      // 「次回お届けの」なので、初回ということはありえず、cart?.isFirstSubscriptionOrderがtrueになるパターンは観測できなかったが一応分岐しておく
      return discountPlan.discountPlan.rate.first;
    } else {
      return discountPlan.discountPlan.rate.normal;
    }
  }
  if (cart?.isFirstSubscriptionOrder || subscription?.isPastDiscount) {
    // 「次回お届けの」なので、初回ということはありえず、cart?.isFirstSubscriptionOrderがtrueになるパターンは観測できなかったが一応if分に入れておく
    return 20;
  }
  return 10;
};
