import { LinkButton } from "@/components/buttons";
import { ImageLegacy } from "@/components/displays";

import bnrPc from "$/images/banner/bnr_shioyakisoba_pc.jpg";
import bnrSp from "$/images/banner/bnr_shioyakisoba_sp.jpg";

export function NewLaunchedProductSection(): React.ReactNode {
  return (
    <section>
      <div className="col-12 col-m-8 col-offset-m-2">
        <p className="text__m text__center text__bold text__red pd__top__l">おすすめ商品</p>
        <p className="text__l text__center text__bold mg__bottom__m">BASE YAKISOBA</p>
        <p className="text__m mg__bottom__m">
          BASE
          YAKISOBAに塩焼きそばが新登場！つるつるもちもち麺と絡み合う、スパイスの効いたあっさり塩焼きそばです。
        </p>
        <ImageLegacy
          src={bnrPc}
          alt="BASE YAKISOBA 塩焼きそば"
          aspectRatio="1920/1080"
          containerClassName="clear__sp"
        />
        <ImageLegacy
          src={bnrSp}
          alt="BASE YAKISOBA 塩焼きそば"
          aspectRatio="1080/1080"
          containerClassName="clear__pc"
        />
        <div className="text__center mg__top__m mg__bottom__l">
          <LinkButton
            href="/products/deli/baseyakisoba/shioyakisoba"
            color="yellow"
            arrow="angle__right"
          >
            新商品についてさらに詳しく
          </LinkButton>
        </div>
      </div>
    </section>
  );
}
