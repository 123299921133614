import clsx from "clsx";
import { StaticImageData } from "next/image";
import Link from "next/link";

import { ImageLegacy } from "@/components/displays";

import styles from "./ImageLinkItem.module.scss";

export interface ImageLink {
  src: string | StaticImageData;
  alt: string;
  isInternalLink?: boolean;
  href?: string;
  onClick?: () => void;
}

export function ImageLinkItem({
  src,
  alt,
  isInternalLink,
  href,
  onClick,
}: ImageLink): React.ReactNode {
  const isBlank = href && href.startsWith("http");
  // 内部リンク、あるいは新しいタブで開くリンクの場合はaタグを使う、それ以外の場合はnext/linkを使う
  const LinkComponent = isInternalLink && !isBlank ? Link : "a";

  return (
    <li className={styles.listItem}>
      {onClick ? (
        <button
          type="button"
          className={clsx(styles.link, styles.button)}
          onClick={onClick}
          data-testid={`ImageLinkItem-${alt}-button`}
        >
          <ImageLegacy
            src={src}
            containerClassName={styles.imageContainer}
            className={styles.image}
            alt={alt}
          />
        </button>
      ) : (
        <LinkComponent
          href={href!}
          className={styles.link}
          target={isBlank ? "_blank" : "_self"}
          data-testid={`ImageLinkItem-${alt}-link`}
        >
          <ImageLegacy
            src={src}
            containerClassName={styles.imageContainer}
            className={styles.image}
            alt={alt}
          />
        </LinkComponent>
      )}
    </li>
  );
}
