"use client";
import { Column } from "@/components/containers";
import { Hr } from "@/components/displays";
import { useParsedGetSubscription } from "@/queries";

import { BasefoodLaboSection } from "./BasefoodLaboSection";
import { ChangeDeliveryDateSection } from "./ChangeDeliveryDateSection";
import { CustomerSupportSection } from "./CustomerSupportSection";
import { DiscountInfoSection } from "./DiscountInfoSection";
import { NewLaunchedProductSection } from "./NewLaunchedProductSection";
import { PointsPackSection } from "./PointsPackSection";
import { PresentSection } from "./PresentSection";
import { RankMileStatusSection } from "./RankMileStatusSection";
import { StatusRedirectManager } from "./StatusRedirectManager";
import { UnsubscribeSection } from "./UnsubscribeSection";
import { UnsubscriptionHeader } from "./UnsubscriptionHeader";

export function MyPageUnsubscription(): React.ReactNode {
  const { data: subscriptionData } = useParsedGetSubscription();
  const discountPlan = subscriptionData.customerDiscountPlan;

  return (
    <>
      <StatusRedirectManager />
      <div className="bg__gray">
        <div className="container col-px-fix-14px">
          <Column>
            <UnsubscriptionHeader />
            <RankMileStatusSection />
            <Hr className="col-10 m-auto" />
            <DiscountInfoSection />
            <Hr className="col-10 m-auto" />
            {!discountPlan && (
              <>
                <PointsPackSection />
                <Hr className="col-10 m-auto" />
              </>
            )}
            <PresentSection />
            <Hr className="col-10 m-auto" />
            <ChangeDeliveryDateSection />
            <Hr className="col-10 m-auto" />
            <NewLaunchedProductSection />
            <Hr className="col-10 m-auto" />
            <BasefoodLaboSection />
            <Hr className="col-10 m-auto" />
            <CustomerSupportSection />
            <Hr className="col-10 m-auto" />
            <UnsubscribeSection />
          </Column>
        </div>
      </div>
    </>
  );
}
