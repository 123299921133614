"use client";
import { useMemo } from "react";

import { Column } from "@/components/containers";
import { DiscountPlanName } from "@/generated/open-api/schemas";

import { AdditionalProductsSection } from "./AdditionalProductsSection";
import { CartProduct, Temperature } from "../../types";
import { Category, SubscriptionProduct, groupProductsByCategory } from "../helper";

const additionalProductsCategories = [
  {
    title: (
      <>
        BASE BREAD<sup className="sup">®</sup>
      </>
    ),
    category: "bread",
    temperature: "normal",
  },
  {
    title: (
      <>
        BASE Pancake Mix<sup className="sup">®</sup>
      </>
    ),
    category: "pancake",
    temperature: "normal",
  },
  {
    title: "BASE YAKISOBA",
    category: "deliYakisoba",
    temperature: "normal",
  },
  {
    // こっちは冷凍じゃない
    title: (
      <>
        BASE PASTA<sup className="sup">®</sup>
      </>
    ),
    category: "pasta",
    temperature: "normal",
  },
  {
    title: (
      <>
        BASE Cookies<sup className="sup">®</sup>
      </>
    ),
    category: "cookie",
    temperature: "normal",
  },
  {
    // こっちは冷凍
    title: (
      <>
        BASE PASTA<sup className="sup">®</sup>
      </>
    ),
    category: "deliPasta",
    temperature: "frozen",
  },
] as const;

interface AdditionalProductsSectionsProps {
  products: SubscriptionProduct[];
  onChangeQuantity?: (products: CartProduct[]) => void;
  temperature: Temperature;
  deliveryDate: Date | null;
  onOpenChanged: (category: Category, open: boolean) => void;
  openedCategories: Record<Category, boolean>;
  isFirstSubscriptionOrder: boolean;
  pastDiscountRate: boolean;
  discountPlanName: DiscountPlanName;
}

export function AdditionalProductsSections({
  products,
  onChangeQuantity,
  temperature,
  deliveryDate,
  onOpenChanged,
  openedCategories,
  isFirstSubscriptionOrder,
  pastDiscountRate,
  discountPlanName,
}: AdditionalProductsSectionsProps): React.ReactNode {
  const groupByProducts = useMemo(() => groupProductsByCategory(products), [products]);

  const additionalCategories = useMemo(
    () => additionalProductsCategories.filter((item) => item.temperature === temperature),
    [temperature]
  );

  return (
    <Column>
      {additionalCategories.map((item) => {
        const products = groupByProducts[item.category];
        if (products.length === 0) return null;
        const isNew = products.some((product) => product.isNew);
        return (
          <AdditionalProductsSection
            key={item.category}
            title={item.title}
            products={products}
            deliveryDate={deliveryDate}
            isFirstSubscriptionOrder={isFirstSubscriptionOrder}
            pastDiscountRate={pastDiscountRate}
            isNew={isNew}
            onChangeQuantity={onChangeQuantity}
            onOpenChanged={(open) => onOpenChanged(item.category, open)}
            open={openedCategories[item.category]}
            discountPlanName={discountPlanName}
          />
        );
      })}
    </Column>
  );
}
