"use client";
import { useMemo } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { ImageLegacy } from "@/components/displays";
import { NutrientModel } from "@/models/nutrient/type";

import styles from "./NutrientCard.module.scss";

interface NutrientCardProps {
  nutrient: NutrientModel;
}

export function NutrientCard({ nutrient }: NutrientCardProps): React.ReactNode {
  const nutrientImage = useMemo(() => {
    const ingredientName = nutrient.ingredientEn;
    return `https://asset.basefood.co.jp/images/parts/food_icon/${ingredientName}.svg`;
  }, [nutrient.ingredientEn]);
  return (
    <Column className={styles.nutrientItem}>
      <Row className={styles.nutrientItemTitle}>
        <p className={clsx("text__m", "text__bold")}>{nutrient.nutrient}</p>
      </Row>
      <Row className={styles.nutrientItemBody}>
        <ImageLegacy
          src={nutrientImage}
          containerClassName={styles.nutrientItemImage}
          alt={nutrient.ingredient}
          size={{ width: 40, height: 40 }}
        />
        <span className="text__m text__bold">{nutrient.ingredient}</span>
        <Row className={styles.amountContainer}>
          <span className="text__red text__xl">{nutrient.ingredientNum}</span>
          <span className="text__red">{nutrient.notationUnit}分</span>
        </Row>
      </Row>
    </Column>
  );
}
