import clsx from "clsx";

import { ImageLegacy } from "@/components/displays";
import { moveToCommune } from "@/components/template/mypage/MyPageIndex/helpers";

import styles from "./OnlineCommunity.module.scss";
import { ImageLink, ImageLinkList } from "../ImageLinkList";

interface OnlineCommunityProps {}

const images: ImageLink[] = [
  {
    src: "https://asset.basefood.co.jp/images/parts/labo_cinnamon.jpg",
    href: "https://labo.basefood.co.jp/view/post/0/26731",
    alt: "シナモンの香りが食欲をそそる！",
  },
  {
    src: "https://asset.basefood.co.jp/images/parts/labo_french_toast.jpg",
    href: "https://labo.basefood.co.jp/view/post/0/173818",
    alt: "フレンチトースト",
  },
];

export function OnlineCommunity({}: OnlineCommunityProps): React.ReactNode {
  return (
    <div className={clsx("bg__yellow", styles.section, styles.labo)}>
      <h3 className={clsx("text__bold", styles.laboTitle)}>
        <span className="text__m">オンラインコミュニティ</span>
        <br />
        <span className="text__l">BASE FOOD Labo</span>
      </h3>
      <p className="text__m mg__top__m mg__bottom__m">
        BASE FOODのユニークな食べ方やスタッフが選ぶ厳選レシピをご紹介！
      </p>

      <ImageLinkList images={images} cols={1} flow="column" />

      <div className="text__center">
        <button type="button" className="btn inline gray round" onClick={moveToCommune}>
          BASE FOOD Labo に参加する
        </button>
      </div>

      <ImageLegacy
        src="https://asset.basefood.co.jp/images/parts/illust_labo_transparent.png"
        className={styles.illustLabo}
        alt="BASE FOOD Labo"
        aspectRatio={"952/331"}
      />
    </div>
  );
}
