import clsx from "clsx";
import Link from "next/link";

import { ImageLegacy } from "@/components/displays";

import styles from "./PointsPackSection.module.scss";
import { ReturnToMyPageLink } from "../ReturnToMyPageLink";

export function PointsPackSection(): React.ReactNode {
  return (
    <section>
      <div className="col-12 col-m-10 col-offset-m-1">
        <p className="text__l text__center text__bold mg__bottom__m pd__top__l">
          <span className="text__xl">ポイントパック</span>
          の利用で
          <br />
          <span className="text__red text__xl">さらにお得！</span>
          です！
        </p>
      </div>
      <div className="col-12 col-m-10 col-offset-m-1">
        <div className="mg__bottom__m">
          <p className="text__m text__left text__center__pc">
            継続コースがさらにお得になる「ポイントパック」がリニューアル！
            <br />
            <span className="text__red text__bold">1,000〜6,600円お得</span>
            に継続コースをご利用いただけます。
          </p>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <div className="col-12 col-m-5 col-offset-m-1 mg__bottom__m">
          <ImageLegacy
            src="https://asset.basefood.co.jp/images/parts/img_pointpack_plan_01_short.png"
            alt="ポイントパックの利用でさらにお得！"
            aspectRatio="392/284"
          />
        </div>
        <div className="col-12 col-m-5 mg__bottom__m">
          <ImageLegacy
            src="https://asset.basefood.co.jp/images/parts/img_pointpack_plan_02_short.png"
            alt="ポイントパックの利用でさらにお得！"
            aspectRatio="392/284"
          />
        </div>
      </div>
      <div className="col-12 col-m-10 col-offset-m-1">
        <p className="text__s text__gray__dark text__left text__center__pc mg__bottom__m">
          ※1 常温配送の送料500円の場合
        </p>
        <div className="text__center mg__bottom__m">
          <Link
            href="/yearplan"
            className={clsx("btn inline round yellow angle__right wsnr", styles.link)}
          >
            ポイントパックについてさらに詳しく
            <i className="fas fa-angle-right" />
          </Link>
        </div>
        <ReturnToMyPageLink data-testid="PointsPackSection-return-button" />
      </div>
    </section>
  );
}
