"use client";

import { useCallback } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { ImageLegacy } from "@/components/displays";
import { Button } from "@/components/inputs";
import { DeliveryStatusType } from "@/generated/open-api/schemas";
import { toJpDateAbbrString } from "@/utils";

import styles from "./MileGiftsTradeItem.module.scss";

const getStatusText = (status: DeliveryStatusType, nextOrderChangeDeadlineDate: string): string => {
  switch (status) {
    case "None":
      return `変更${toJpDateAbbrString(nextOrderChangeDeadlineDate)}まで`;
    case "Cancel":
      return "注文キャンセル済み";
    case "PartlyShipped":
      return "配送準備中";
    case "Shipped":
      return "出荷済み";
  }
};

export interface MileGiftsTradeItemProps {
  id: number;
  name: string;
  imageUrl: string;
  nextOrderChangeDeadlineDate: string;
  deliveryDate: string;
  deliveryStatus: DeliveryStatusType;
  onCancel?: (id: number) => void;
}

export function MileGiftsTradeItem({
  id,
  name,
  imageUrl,
  nextOrderChangeDeadlineDate,
  deliveryDate,
  deliveryStatus,
  onCancel,
}: MileGiftsTradeItemProps): React.ReactNode {
  const handleCancel = useCallback(() => {
    onCancel?.(id);
  }, [id, onCancel]);

  return (
    <Row gap={10}>
      <ImageLegacy src={imageUrl} alt={name} size={{ width: 80, height: 80 }} />
      <Column gap={10}>
        <Column gap={5}>
          <div className={styles.title}>{name}</div>
          <div className={styles.deliveryData}>{toJpDateAbbrString(deliveryDate)}にお届け予定</div>
          <div
            className={clsx(
              styles.nextOrderChangeDeadlineDate,
              styles[`deliveryStatus${deliveryStatus}`]
            )}
          >
            {getStatusText(deliveryStatus, nextOrderChangeDeadlineDate)}
          </div>
        </Column>
        {deliveryStatus === "None" && (
          <div>
            <Button size="xs" variants="gray" onClick={handleCancel}>
              取り消す
            </Button>
          </div>
        )}
      </Column>
    </Row>
  );
}
