"use client";

import { useMemo } from "react";

import clsx from "clsx";
import { format } from "date-fns";
import { useParams } from "next/navigation";

import { Column, Row } from "@/components/containers";
import { Chip, Hr, ImageLegacy, LoadingOverlay, MoneySpan, Paper } from "@/components/displays";
import { PlanDiscountStatusCard, PlanDiscountStatusCardProps } from "@/components/domains/3month";
import { useDiscountPlans, useParsedGetCustomerOrderDetail } from "@/queries";

import { getPaymentAmount, getProductPrice, isDaibiki } from "./helpers";
import styles from "./MyPageOrderDetail.module.scss";
import { getPaymentStatus } from "../MyPagePurchaseHistory/PurchaseHistoryTable/helpers";

export function MyPageOrderDetail() {
  const params = useParams<{ orderId: string }>();
  const { data: order, isLoading } = useParsedGetCustomerOrderDetail(params.orderId);
  const { data: discountPlans } = useDiscountPlans();

  const planDiscountStatusCardProps = useMemo<PlanDiscountStatusCardProps | null>(() => {
    if (!order) return null;
    const discountPlan = discountPlans[order.discountPlanName ?? "DEFAULT"];
    if (!discountPlan) return null;
    if (!order.isSubscription) {
      // 1回のみお届けの場合
      return {};
    }
    const totalDiscountRate = order.subscriptionDiscountRate + (order.discountPlanRate ?? 0);
    return {
      planName: discountPlan.plan_name,
      normalDiscountRate: totalDiscountRate,
    };
  }, [discountPlans, order]);

  return (
    <div className={styles.root}>
      <LoadingOverlay isLoading={isLoading}>
        {order && (
          <div className="flex flex-wrap">
            <article className="col-12 col-m-6 col-px-fix">
              <Paper padding="responsive" className="mg__bottom__m">
                <h3 className="text__l text__bold mg__bottom__m">注文詳細</h3>
                <dl className="table__dl pd__bottom__s">
                  <dt>
                    <p className="text__m">注文番号：</p>
                  </dt>
                  <dd className="text__right">
                    <span className="text__m">{order.id}</span>
                  </dd>
                </dl>
                <dl className="table__dl pd__bottom__s">
                  <dt>
                    <p className="text__m">注文日：</p>
                  </dt>
                  <dd className="text__right">
                    <span className="text__m">
                      {order.createdAt && format(order.createdAt, "yyyy/MM/dd")}
                    </span>
                  </dd>
                </dl>
                {order.cancelledAt && (
                  <dl className="table__dl pd__bottom__s">
                    <dt>
                      <p className="text__m">キャンセル日</p>
                    </dt>
                    <dd className="text__right">
                      <span className="text__m">
                        {order.cancelledAt && format(order.cancelledAt, "yyyy/MM/dd")}
                      </span>
                    </dd>
                  </dl>
                )}
                <dl className="table__dl pd__bottom__s">
                  <dt>
                    <p className="text__m">支払い状況：</p>
                  </dt>
                  <dd className="text__right">
                    <span className="text__m">{getPaymentStatus(order)}</span>
                  </dd>
                </dl>
              </Paper>
              {order.shippingAddress && (
                <Paper padding="responsive" className="mg__bottom__m">
                  <h3 className="text__l text__bold mg__bottom__m">配送先住所</h3>
                  <p className="text__m text__bold">
                    {order.shippingAddress.lastName}
                    {order.shippingAddress.firstName}
                  </p>
                  <p className="text__m">
                    {order.shippingAddress.zip?.slice(0, 3)}
                    <span>-</span>
                    {order.shippingAddress.zip?.slice(-4)}
                  </p>
                  <p className="text__m">
                    {order.shippingAddress.convertedProvince}
                    {order.shippingAddress.city}
                    {order.shippingAddress.addressLine1}
                    {order.shippingAddress.addressLine2}
                  </p>
                </Paper>
              )}
              {order.billingAddress && (
                <Paper padding="responsive" className="mg__bottom__m">
                  <h3 className="text__l text__bold mg__bottom__m">請求先住所</h3>
                  <p className="text__m text__bold">
                    {order.billingAddress.zip?.slice(0, 3)}
                    <span>-</span>
                    {order.billingAddress.zip?.slice(-4)}
                  </p>
                  <p className="text__m">
                    {order.billingAddress.convertedProvince}
                    {order.billingAddress.city}
                    {order.billingAddress.addressLine1}
                    {order.billingAddress.addressLine2}
                  </p>
                </Paper>
              )}
            </article>
            <section className="col-12 col-m-6 col-px-fix">
              <Paper padding="responsive">
                <h1 className="text__l text__bold mg__bottom__m">注文内容</h1>
                {planDiscountStatusCardProps && (
                  <Column className="mg__bottom__m">
                    <PlanDiscountStatusCard
                      {...planDiscountStatusCardProps}
                      discountMessageColor="black"
                    />
                  </Column>
                )}
                <div className={styles.borderTop}>
                  {order.products.map((product) => (
                    <div key={product.variantId}>
                      <div className="flex pd__top__m">
                        <div>
                          <ImageLegacy
                            src={product.images?.[product.variantImageIndex || 0] || ""}
                            alt={`${product.title}${product.variantTitle}`}
                            className={styles.productThumbnail}
                            size={{ width: 88, height: 88 }}
                          />
                        </div>
                        <div className={styles.productTitle}>
                          <p>{product.title}</p>
                          <p>{product.variantTitle}</p>
                        </div>
                      </div>
                      <Row align="center" justify="space-between">
                        <span className="text__m">
                          {product.quantity}
                          {product.meal}
                        </span>
                        <Row>
                          {product.isBrandNewDiscount && (
                            <Chip color="red" variant="outlined" className={styles.chip}>
                              ランク特典30%OFF
                            </Chip>
                          )}
                          <MoneySpan suffix="*" className="text__gray__dark text__l text__bold">
                            {getProductPrice(product, order)}
                          </MoneySpan>
                        </Row>
                      </Row>
                    </div>
                  ))}
                </div>
                <div className={clsx("mg__top__m", styles.borderTop)}>
                  {order.coupon && (
                    <>
                      <Row className={clsx("pd__bottom__m", "pd__top__m", styles.couponTitle)}>
                        <p className="text__m">クーポン：</p>
                        <span className="text__l text__bold text__right">{order.coupon.code}</span>
                      </Row>
                      <Hr noMargin />
                    </>
                  )}
                  <dl className="table__dl pd__bottom__s pd__top__m">
                    <dt>
                      <p className="text__m">小計：</p>
                    </dt>
                    <dd className="text__right">
                      <MoneySpan className="text__l text__bold">
                        {order.totalLineItemsPrice}
                      </MoneySpan>
                    </dd>
                  </dl>
                  <dl className="table__dl pd__bottom__s">
                    <dt>
                      <p className="text__m">配送料：</p>
                    </dt>
                    <dd className="text__right">
                      <MoneySpan
                        className={clsx("text__l text__bold", !order.shippingFee && "text__red")}
                        suffix={order.shippingFee ? "**" : ""}
                      >
                        {order.shippingFee}
                      </MoneySpan>
                    </dd>
                  </dl>
                  <dl v-if="order.is_show_cash_delivery_fee" className="table__dl pd__bottom__s">
                    <dt>
                      <p className="text__m">代引き手数料：</p>
                    </dt>
                    <dd className="text__right">
                      <MoneySpan className="text__l text__bold" suffix="**">
                        {order.totalPriceCashDeliveryFee}
                      </MoneySpan>
                    </dd>
                  </dl>
                  <dl className="table__dl pd__bottom__s">
                    <dt>
                      <p className="text__m">クーポン値引き：</p>
                    </dt>
                    <dd className="text__right">
                      <MoneySpan
                        className="text__l text__bold text__red"
                        prefix={order.couponDiscount ? "-" : ""}
                      >
                        {order.couponDiscount}
                      </MoneySpan>
                    </dd>
                  </dl>
                  <dl className="table__dl pd__bottom__s">
                    <dt>
                      <p className="text__m">ポイント値引き：</p>
                    </dt>
                    <dd className="text__right">
                      <MoneySpan
                        className="text__l text__bold text__red"
                        prefix={order.pointDiscount ? "-" : ""}
                      >
                        {order.pointDiscount}
                      </MoneySpan>
                    </dd>
                  </dl>
                  <dl className="table__dl mg__top__m">
                    <dt>
                      <p className="text__m wsnr">合計：</p>
                    </dt>
                    <dd className="text__right">
                      <MoneySpan className="text__xl text__bold">
                        {getPaymentAmount(order)}
                      </MoneySpan>
                      <p className="text__s">
                        ※価格はすべて税込表示
                        <br className="clear__pc" />
                        *税率8% **税率10%
                        {isDaibiki(order) && (
                          <p>
                            <br />
                            ※代引き手数料別
                          </p>
                        )}
                      </p>
                    </dd>
                  </dl>
                </div>
              </Paper>
            </section>
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
}
