import { useMemo } from "react";

import { moveToCommune } from "@/components/template/mypage/MyPageIndex/helpers";
import { useGetMypageLink } from "@/generated/open-api/customer/customer";
import { useParsedGetSubscription } from "@/queries";

import anyGiftLinkImage from "$/images/banner/bnr_anygift.jpg";
import inviteBanner from "$/images/banner/bnr_invite_W1080_H1080.png";
import safetyBanner from "$/images/banner/bnr_safety_W750_H750.jpg";
import outletLinkImage from "$/images/outlet/mypage_outlet_link.png";

import { ImageLink } from "../ImageLinkList";

const images: ImageLink[] = [
  {
    src: anyGiftLinkImage,
    alt: "BASE FOODを贈ろう eGift",
    isInternalLink: true,
    href: "/egift",
  },
  {
    src: inviteBanner,
    alt: "友だち紹介キャンペーン",
    isInternalLink: true,
    href: "/invite",
  },
  {
    src: "https://asset.basefood.co.jp/images/bnr/bnr_pointpack_W1080_H1080.png",
    alt: "ポイントパックのご案内",
    isInternalLink: true,
    href: "/yearplan",
  },
  {
    src: safetyBanner,
    alt: "BASE FOODの安全",
    href: "https://basefood.co.jp/safety",
  },
  {
    src: "https://asset.basefood.co.jp/images/bnr/bnr_labo_W466_H466.png",
    alt: "BASE FOOD labo",
    onClick: () => moveToCommune(),
  },
  {
    src: "https://asset.basefood.co.jp/images/bnr/bnr_brandbook_W1080_H1080.jpg",
    alt: "ブランドについて",
    href: "https://basefood.co.jp/brand",
  },
  {
    src: "https://asset.basefood.co.jp/images/bnr/bnr_recruit_W466_H466.png",
    alt: "採用情報",
    href: "https://docs.google.com/forms/d/e/1FAIpQLSc_ILZhFdFTzxzOvgm_xZXhdhEJpmfT4GHjpB_UMX9_2gU_Hw/viewform",
  },
];

export const useImageLinks = () => {
  const { data: subscription } = useParsedGetSubscription();
  const { data: outletLink } = useGetMypageLink({
    query: { enabled: !!subscription.subscription || !!subscription.freezeSubscription },
  });

  const imageLinks = useMemo(() => {
    if (!outletLink?.is_display_outlet_link) return images;

    const outletImage: ImageLink = {
      src: outletLinkImage,
      alt: "アウトレット商品のご案内",
      isInternalLink: true,
      href: "/outlet/bolognese4",
    };

    return [outletImage, ...images];
  }, [outletLink]);

  return { imageLinks };
};
