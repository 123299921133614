import React from "react";

import clsx from "clsx";
import Link from "next/link";

import styles from "./LinkButton.module.scss";

export interface LinkButtonProps {
  children: React.ReactNode;
  href: string;
  color?: "yellow" | "gray" | "red" | "blue" | "border__bk" | "border__wh";
  arrow?: "angle__left" | "angle__right" | "angle__up" | "angle__down";
  noMargin?: boolean;
  size?: "mini";
  "data-testid"?: string;
}

/**
 * @returns
 */
export function LinkButton({
  href,
  color = "gray",
  arrow,
  noMargin,
  size,
  "data-testid": testId,
  children,
}: LinkButtonProps) {
  return (
    <Link
      href={href}
      data-testid={testId}
      className={clsx(
        "btn inline round",
        color,
        arrow,
        noMargin && styles.noMargin,
        arrow === "angle__down" && "angle__right",
        arrow === "angle__up" && "angle__right",
        size
      )}
    >
      {arrow === "angle__left" && <i className="fas fa-angle-left" />}
      {children}
      {arrow === "angle__right" && <i className="fas fa-angle-right" />}
      {arrow === "angle__down" && <i className="fas fa-angle-down" />}
      {arrow === "angle__up" && <i className="fas fa-angle-up" />}
    </Link>
  );
}
