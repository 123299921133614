import { allProductsNames } from "@/models/product/consts";

type ProductName = (typeof allProductsNames)[number];

export const sortProducts = <P extends { name: string }>(products: P[]): P[] => {
  // allProductsNamesの順番にproductsを並び替える
  return [...products].sort((a, b) => {
    return (
      allProductsNames.indexOf(a.name as ProductName) -
      allProductsNames.indexOf(b.name as ProductName)
    );
  });
};

export const appendProductNameByVariantId = <P extends { variantId: number }>(
  product: P,
  baseProducts: { variantId: number; name: string }[]
): P & { name: ProductName } => {
  const baseProduct = baseProducts.find((base) => base.variantId === product.variantId);
  return {
    ...product,
    name: (baseProduct?.name ??
      (product as unknown as { name: ProductName }).name ?? // ここには到達しない想定
      "") as ProductName,
  };
};
