import clsx from "clsx";

import styles from "./DiscountInfoSection.module.scss";
import { ReturnToMyPageLink } from "../ReturnToMyPageLink";

export function DiscountInfoSection(): React.ReactNode {
  return (
    <section>
      <div className="col-12 col-m-10 col-offset-m-1">
        <p className="text__l text__center text__bold mg__bottom__m pd__top__l">
          <span className="text__xl">当サイト</span>の<span className="text__xl">継続コース</span>
          が
          <br />
          <span className="text__red text__xl">お得</span>
          です！
        </p>
      </div>
      <div className="col-12 col-m-6 col-offset-m-3">
        <div className={clsx("bg__white mg__bottom__m rounded-10px", styles.priceTableContainer)}>
          <table className={styles.priceTable}>
            <tbody>
              <tr>
                <td className="text__s wsnr">（1袋あたり）</td>
                <td className="text__s">
                  BASE BREAD
                  <br />
                  チョコレート
                </td>
                <td className="text__s">
                  BASE BREAD
                  <br />
                  プレーン
                </td>
                <td className="text__s">
                  BASE BREAD
                  <br />
                  カレー
                </td>
              </tr>
              <tr>
                <td className="text__red text__s text__bold">
                  継続コース<sup className="sup">※1</sup>
                </td>
                <td className="text__red text__s text__bold">
                  <span className="text__m">229</span>円
                </td>
                <td className="text__red text__s text__bold">
                  <span className="text__m">209</span>円
                </td>
                <td className="text__red text__s text__bold">
                  <span className="text__m">260</span>円
                </td>
              </tr>
              <tr>
                <td className="text__s text__bold">1回のみ購入</td>
                <td className="text__s text__bold">
                  <span className="text__m">255</span>円
                </td>
                <td className="text__s text__bold">
                  <span className="text__m">233</span>円
                </td>
                <td className="text__s text__bold">
                  <span className="text__m">289</span>円
                </td>
              </tr>
              <tr>
                <td className="text__s text__bold">
                  コンビニ価格<sup className="sup">※2</sup>
                </td>
                <td className="text__s text__bold">
                  <span className="text__m">281</span>円
                </td>
                <td className="text__s text__bold">
                  <span className="text__m">268</span>円
                </td>
                <td className="text__s text__bold">
                  <span className="text__m">298</span>円
                </td>
              </tr>
            </tbody>
          </table>
          <p className="text__s text__gray__dark mg__top__s">
            ※1 2回目以降10%OFF。
            <br />
            ※2 BASE
            BREADの税抜希望小売価格（プレーン248円、チョコレート260円）に消費税率を乗じた場合（小数点以下切り捨て）。ただしコンビニやドラッグストアでの特売価格およびその他一部小売店舗でのセット販売は除く
          </p>
        </div>
      </div>
      <div className="col-12 col-m-10 col-offset-m-1">
        <div className="text__left text__center__pc mg__bottom__m">
          <p className="text__m">
            継続コースは、通常価格より常に
            <span className="text__red text__bold">10%OFF</span>
            でご購入いただくことができます。
          </p>
        </div>
        <div className="text__center mg__bottom__m">
          <a
            href="https://basefood.zendesk.com/hc/ja/articles/35645286402201"
            className="btn inline round yellow angle__right"
            target="_blank"
            data-testid="DiscountInfoSection-price-detail-button"
          >
            価格についてさらに詳しく
            <i className="fas fa-angle-right" />
          </a>
        </div>
        <ReturnToMyPageLink data-testid="DiscountInfoSection-return-button" />
      </div>
    </section>
  );
}
