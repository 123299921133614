"use client";

import { useMemo, useState } from "react";

import clsx from "clsx";

import { Paper } from "@/components/displays";
import { Tabs } from "@/components/layouts";
import { useGetCustomerPenaltyPayments } from "@/generated/open-api/customer/customer";
import { usePrefetchCallbacks } from "@/utils/hooks";

import styles from "./MyPagePurchaseHistory.module.scss";
import { PenaltyPaymentTable } from "./PenaltyPaymentTable";
import { PointHistoryTable } from "./PointHistoryTable";
import { PurchaseHistoryTable } from "./PurchaseHistoryTable";

type TabValue = "purchase" | "point" | "penalty";

export function MyPagePurchaseHistory(): React.ReactNode {
  const { data: penaltyPaymentsData } = useGetCustomerPenaltyPayments({ page: 1 });
  const { prefetchOrdersCallback, prefetchPointsCallback } = usePrefetchCallbacks();

  const tabItems = useMemo(() => {
    const hasPenalty = (penaltyPaymentsData?.data?.length ?? 0) > 0;
    const base = [
      {
        label: "注文",
        value: "purchase",
        onMouseEnter: prefetchOrdersCallback({ page: 1 }),
        onFocus: prefetchOrdersCallback({ page: 1 }),
      },
      {
        label: "ポイント",
        value: "point",
        onMouseEnter: prefetchPointsCallback({ page: 1 }),
        onFocus: prefetchPointsCallback({ page: 1 }),
      },
    ] as const;
    if (hasPenalty) {
      return [
        ...base,
        {
          label: "請求",
          value: "penalty",
          // prefetchはhasPenaltyの計算時に取得しているため不要
        } as const,
      ];
    } else {
      return base;
    }
  }, [penaltyPaymentsData?.data?.length, prefetchOrdersCallback, prefetchPointsCallback]);

  const [activeTab, setActiveTab] = useState<TabValue>("purchase");

  return (
    <Paper padding="fixed40" className={clsx("bg__white", styles.container)}>
      <div className="text__m text__center">
        <Tabs value={activeTab} items={tabItems} className={styles.tabs} onChange={setActiveTab} />
      </div>
      {activeTab === "purchase" && <PurchaseHistoryTable />}
      {activeTab === "point" && <PointHistoryTable />}
      {activeTab === "penalty" && <PenaltyPaymentTable />}

      <p className="text__s mg__top__s">
        ※一部、過去の注文が表示されないことがあります。詳細を確認したい場合、お問い合わせください。
      </p>
      <p className="text__s mg__top__s">
        2022/05/24 14:00以降の注文より支払い金額が代引き手数料を含む価格に変更しました。
      </p>
    </Paper>
  );
}
