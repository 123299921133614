"use client";
import clsx from "clsx";
import Link from "next/link";

import { LinkButton } from "@/components/buttons";
import { Column } from "@/components/containers";
import { Hr } from "@/components/displays";
import { ThreeMonthRetentionCard } from "@/components/domains/3month";
import { MileRankInfo } from "@/components/domains/mypage";
import { useParsedGetSubscription } from "@/queries";

import { RankMileStatusMileSummary } from "./RankMileStatusMileSummary";
import styles from "./RankMileStatusSection.module.scss";
import { ReturnToMyPageLink } from "../ReturnToMyPageLink";

export function RankMileStatusSection(): React.ReactNode {
  const { data: subscriptionData } = useParsedGetSubscription();
  const discountPlan = subscriptionData.customerDiscountPlan;
  return (
    <section>
      <div className="col-12 col-m-6 col-offset-m-3 mg__top__m">
        <p className={clsx("text__m text__white text__bold rounded-10px", styles.warningMessage)}>
          まだ解約は完了していません。
          <br />
          ご解約前に必ずご確認ください。
        </p>
      </div>
      {discountPlan && discountPlan.needToGoalCount > 0 && (
        <>
          <Column gap={20} className="col-12 col-m-6 col-offset-m-3 mg__top__m mg__bottom__m">
            <p className={clsx("text__l", "text__bold", "text__center")}>
              {discountPlan.discountPlan.planName}に加入中のため
              <br />
              追加請求が発生します。
            </p>
            <ThreeMonthRetentionCard
              restDays={discountPlan.needToGoalDays}
              restOrderCount={discountPlan.needToGoalCount}
              planDiscountedAmount={discountPlan.planDiscountedAmount}
            />
            <div className="text__center mg__bottom__m">
              <LinkButton
                href="/mypage"
                arrow="angle__left"
                color="yellow"
                data-testid="RankMileStatusSection-return-button"
              >
                解約せずにマイページに戻る
              </LinkButton>
            </div>
          </Column>
          <Hr className="col-10 m-auto" />
        </>
      )}

      <div className="col-12 col-m-10 col-offset-m-1 mg__top__l">
        <h1 className="text__l text__bold text__center mg__bottom__m">
          <span className="wsnr">解約すると</span>
          <wbr />
          <span className="wsnr">
            <span className="text__xl">ランク</span>と<span className="text__xl">マイル</span>が
          </span>
          <br />
          <span className="text__xl">リセット</span>
          されてしまいます！
        </h1>
        <div className="bg__white mg__top__m mg__bottom__m rounded-10px">
          <MileRankInfo rankCircleBgColor="#f0f0f0" />
        </div>
        <div className="bg__white mg__top__m mg__bottom__l rounded-10px">
          <RankMileStatusMileSummary />
        </div>
        <p className="text__m text__center__pc mg__top__m">
          貯まったマイルは、公式ノベルティや商品と交換することができます。
        </p>
        <p className="text__m text__center__pc mg__bottom__m">
          また、ランクごとに
          <span className="text__red text__bold">さまざまな特典</span>
          をご用意しておりますので、いま一度ご確認ください！
        </p>
      </div>

      <div className="col-12 col-m-6 col-offset-m-3">
        <div className="text__center mg__bottom__m mg__top__m">
          <Link
            href="/mileprogram"
            className={clsx("btn inline round yellow angle__right", styles.widthControl)}
            target="_blank"
            data-testid="RankMileStatusSection-mile-detail-button"
          >
            ランクと特典についてさらに詳しく
            <i className="fas fa-angle-right" />
          </Link>
        </div>
        <ReturnToMyPageLink data-testid="RankMileStatusSection-return-button" />
      </div>
    </section>
  );
}
