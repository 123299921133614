import { useMemo } from "react";

import clsx from "clsx";
import Link from "next/link";

import { SubscriptionResponseModel } from "@/models/subscription/type";
import { useParsedGetCustomer } from "@/queries";

import styles from "./DeliveryStatus.module.scss";
import { calcNextDeliveryDateString, DeliveryDate, getNextDeliveryPageUrl } from "../helpers";

interface DeliveryStatusProps {
  subscription: SubscriptionResponseModel;
  normalDeliveryDate?: DeliveryDate;
  freezeDeliveryDate?: DeliveryDate;
}

export function DeliveryStatus({
  subscription,
  normalDeliveryDate,
  freezeDeliveryDate,
}: DeliveryStatusProps): React.ReactNode {
  const { data: customer } = useParsedGetCustomer();

  const nextDeliveryDate = useMemo(
    () => calcNextDeliveryDateString(normalDeliveryDate, freezeDeliveryDate),
    [normalDeliveryDate, freezeDeliveryDate]
  );

  const nextDeliveryPageUrl = useMemo(
    () => getNextDeliveryPageUrl(normalDeliveryDate, freezeDeliveryDate),
    [normalDeliveryDate, freezeDeliveryDate]
  );

  return (
    <div className={clsx("bg__yellow", styles.customer)}>
      <div className="text__s">
        {customer.lastName} {customer.firstName} さま
      </div>
      {nextDeliveryPageUrl && (
        <div className="text__right text__s text__bold">
          <Link className={clsx("text__s text__bold", styles.button)} href={nextDeliveryPageUrl}>
            <span className={styles.underline}>{nextDeliveryDate}</span>
          </Link>
        </div>
      )}
      {!subscription.subscription && !subscription.freezeSubscription && (
        <div className="text__right text__s text__bold">
          <Link className={clsx("text__s text__bold", styles.button)} href="/mypage/subscription">
            <span className={styles.underline}>継続コースを開始する</span>
          </Link>
        </div>
      )}
    </div>
  );
}
