"use client";
import { useCallback } from "react";

import clsx from "clsx";

import { ImageLegacy } from "@/components/displays";
import { MileIcon } from "@/components/icons";
import { Button } from "@/components/inputs";
import { numberFormat } from "@/utils";

import styles from "./MileGiftItem.module.scss";

export interface MileGiftItemProps {
  id: number;
  name: string;
  imageUrl: string;
  imageAlt?: string;
  miles: number;
  isExchangeable?: boolean;
  onExchange: (id: number) => void;
}

export function MileGiftItem({
  id,
  imageUrl,
  imageAlt,
  name,
  miles,
  isExchangeable = true,
  onExchange,
}: MileGiftItemProps): React.ReactNode {
  const handleExchange = useCallback(() => {
    onExchange(id);
  }, [id, onExchange]);

  return (
    <dl className={styles.container}>
      <dt className={styles.detail}>
        <ImageLegacy src={imageUrl} alt={imageAlt ?? name} size={{ width: 80, height: 80 }} />
        <div className={styles.mile}>
          <p className="text__m">{name}</p>
          <p className={clsx("text__m text__bold", styles.iconContainer)}>
            <MileIcon className={styles.icon} />
            {numberFormat(miles)}
          </p>
        </div>
      </dt>
      <dd>
        <Button
          variants="yellow"
          size="xs"
          fullWidth
          disabled={!isExchangeable}
          onClick={handleExchange}
        >
          交換する
        </Button>
      </dd>
    </dl>
  );
}
