"use client";

import clsx from "clsx";
import { format } from "date-fns";
import { useParams } from "next/navigation";

import { ImageLegacy, LoadingOverlay, MoneySpan } from "@/components/displays";
import { useParsedGetCustomerPointDetail } from "@/queries";

import styles from "./MyPagePointDetail.module.scss";

export function MyPagePointDetail() {
  const params = useParams<{ pointId: string }>();
  const { data: point, isLoading } = useParsedGetCustomerPointDetail(params.pointId);

  return (
    <div className={styles.root}>
      <LoadingOverlay isLoading={isLoading}>
        {point && (
          <div className="flex flex-wrap">
            <article className="col-12 col-m-6 col-px-fix">
              <div className={clsx("bg__white mg__bottom__m", styles.section)}>
                <h3 className="text__l text__bold mg__bottom__m">注文詳細</h3>
                <dl className="table__dl pd__bottom__s">
                  <dt>
                    <p className="text__m">注文日：</p>
                  </dt>
                  <dd className="text__right">
                    <span className="text__m">
                      {point.paidPoint && format(point.paidPoint.createdAt, "yyyy/MM/dd")}
                    </span>
                  </dd>
                </dl>
                <dl className="table__dl pd__bottom__s">
                  <dt>
                    <p className="text__m">支払い状況：</p>
                  </dt>
                  <dd className="text__right">
                    <span className="text__m text__bold">支払い済み</span>
                  </dd>
                </dl>
              </div>
            </article>
            <section className="col-12 col-m-6 col-px-fix">
              <div className={clsx("bg__white", styles.section)}>
                <h1 className="text__l text__bold mg__bottom__m">注文内容</h1>
                <div className={styles.borderTop}>
                  <div>
                    <div className="flex pd__top__m">
                      <div>
                        <ImageLegacy
                          src="https://asset.basefood.co.jp/images/pkg/bnr_pointpack_thumbnail_v2.png"
                          alt="いちねんポイントパック"
                          className={styles.image}
                          size={{ width: 88, height: 88 }}
                        />
                      </div>
                      <div className={styles.title}>
                        <p>{point.paidPoint?.label}</p>
                        <p>{point.paidPoint?.point.toLocaleString()}pt</p>
                      </div>
                    </div>
                    <div className="flex align-center">
                      <span className="text__m"> 数量：1 </span>
                      <MoneySpan className="text__gray__dark text__l text__bold ml-auto" suffix="*">
                        {point.pointOrder?.price}
                      </MoneySpan>
                    </div>
                    <div className={clsx("mg__top__m", styles.borderTop)}>
                      <dl className="table__dl mg__top__m">
                        <dt>
                          <p className="text__m wsnr">合計：</p>
                        </dt>
                        <dd className="text__right">
                          <MoneySpan className="text__xxl text__bold">
                            {point.pointOrder?.price}
                          </MoneySpan>
                          <p className="text__s">※価格はすべて税込表示です</p>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
}
