"use client";
import { useCallback, useMemo, useState } from "react";

import { format, isAfter, parse } from "date-fns";
import { useRouter, useSearchParams } from "next/navigation";
import toast from "react-hot-toast";
import { scroller } from "react-scroll";

import { Column, Row } from "@/components/containers";
import { LoadingOverlay } from "@/components/displays";
import { ThreeMonthStatusAccordion } from "@/components/domains/3month";
import { htmlToast } from "@/components/feedbacks";
import { buildCheckoutUrl } from "@/features";
import { UsePointType } from "@/generated/open-api/schemas";
import { useDeleteSubscription } from "@/generated/open-api/subscription/subscription";
import {
  frozenTemperatureProductsNames,
  normalTemperatureProductsNames,
} from "@/models/product/consts";
import { ProductModel } from "@/models/product/type";
import {
  useGetParsedProductsByNames,
  useParsedAddSubscription,
  useParsedGetCustomer,
  useParsedGetMileGiftsTrades,
  useParsedGetOrderWithDeliveryDate,
  useParsedGetSubscription,
  useParsedUpdateSubscriptionPoint,
} from "@/queries";
import { sumBy } from "@/utils/array";
import { getErrorMessages } from "@/utils/error";
import {
  useAsync,
  useCount,
  useModalState,
  useRegisterDetectDataChangeFunctions,
} from "@/utils/hooks";

import { DeliverySchedule } from "./DeliverySchedule";
import { DeliveryScheduleFormSchemaValue } from "./DeliverySchedule/DeliveryScheduleForm/schema";
import { GiftCancellationAlertModal } from "./GiftCancellationAlertModal";
import {
  getCanChangeOrder,
  useCouponValidations,
  useDeliveryScheduleValues,
  useUpdateSubscriptionSubmit,
  useSubscriptionCartValues,
  convertSubscriptionToScheduleValues,
  getBrandNewDiscountMessages,
  CouponValidationError,
  CouponValidationErrorType,
  getDiscountPlanWarningMessages,
} from "./helper";
import styles from "./MyPageSubscription.module.scss";
import { MyPageSubscriptionBottomActions } from "./MyPageSubscriptionBottomActions";
import { NextDelivery } from "./NextDelivery";
import { PaymentSummary } from "./PaymentSummary";
import { SkipModal, SkipModalEvent } from "./SkipModal";
import { SubscriptionSection } from "./SubscriptionSection";
import { CartProduct, Temperature } from "./types";

type MyPageSubscriptionProps = {
  temperature: Temperature;
};

const nextProductsSectionId = "next-products-section";

export function MyPageSubscription({ temperature }: MyPageSubscriptionProps) {
  const router = useRouter();
  const [loadingCount, startLoading, endLoading] = useCount();
  const isLoading = loadingCount > 0;
  const { data: customer } = useParsedGetCustomer();
  const { data: subscription, refetch: refetchSubscription } = useParsedGetSubscription();
  const hasSubscription = !!(subscription.subscription || subscription.freezeSubscription);
  const hasTheOtherSubscription =
    temperature === "normal" ? !!subscription.freezeSubscription : !!subscription.subscription;
  const targetSubscription =
    temperature === "normal" ? subscription.subscription : subscription.freezeSubscription;
  const comebackProducts =
    temperature === "normal"
      ? subscription.comebackSubscriptionProducts
      : subscription.comebackFreezeSubscriptionProducts;
  const totallyUnsubscribed = !subscription.subscription && !subscription.freezeSubscription;

  const productNames =
    temperature === "normal" ? normalTemperatureProductsNames : frozenTemperatureProductsNames;
  const { data: allProductsData } = useGetParsedProductsByNames({ names: [...productNames] });
  const allProducts = useMemo(
    () => allProductsData.map((product) => ({ ...product, quantity: 0 })),
    [allProductsData]
  );
  const discountPlan = subscription?.customerDiscountPlan;

  const [isExistAdditionalProducts, setIsExistAdditionalProducts] = useState(false);
  // URLからvariant_idを取得
  const params = useSearchParams();
  const additionalVariantIds = (params.get("variant_id") || "").split(",");

  // variantIdに含まれる商品を注文内容に追加
  const addProductsFromVariantIdParameters = function (products: ProductModel[]) {
    // URLにvariant_idが含まれる場合はそちらを定期便に追加して表示する
    const addedProducts = [...products];
    additionalVariantIds.forEach((variantId) => {
      const newProduct = allProducts.find((p) => p.variantId === Number(variantId));
      if (newProduct) {
        const index = products.findIndex((product) => product.variantId === newProduct.variantId);
        // 定期便の選択内容に新商品が存在しない場合は追加
        if (index === -1) {
          const quantity = newProduct.selectOptions.filter((option) => {
            return option > 0;
          });
          addedProducts.push({ ...newProduct, quantity: quantity[0] ?? 0 });
        }
      }
    });

    if (addedProducts.length !== products.length) {
      setIsExistAdditionalProducts(true);
    }
    return addedProducts;
  };
  // 商品選択
  const [selectedProducts, setSelectedProducts] = useState<CartProduct[]>(() =>
    addProductsFromVariantIdParameters(targetSubscription?.products ?? [])
  );
  const totalQuantity = useMemo(() => sumBy(selectedProducts, "quantity"), [selectedProducts]);
  // 変更した商品のvariantIdとquantityを更新
  const handleChangeQuantity = useCallback((products: CartProduct[]) => {
    setSelectedProducts((prev) => {
      return products.reduce(
        (newProducts, product) => {
          const index = newProducts.findIndex((prev) => prev.variantId === product.variantId);
          if (index === -1) {
            // 既存の選択商品にない場合は追加
            return [...newProducts, product];
          } else {
            // 既存の選択商品にある場合は更新
            newProducts[index] = product;
            return newProducts;
          }
        },
        [...prev]
      );
    });
  }, []);

  // ポイント状況
  const [pointUsage, setPointUsage] = useState<UsePointType>(
    targetSubscription?.nextUsePointType ?? "none"
  );
  const { mutateAsync: updatePoint } = useParsedUpdateSubscriptionPoint();
  const handleChangePointUsage = useCallback(
    async (pointUsage: UsePointType) => {
      if (!targetSubscription) return;
      try {
        startLoading();
        await updatePoint({
          data: { ...targetSubscription, nextUsePointType: pointUsage },
        });
        // 更新成功したら画面上に反映
        setPointUsage(pointUsage);
      } catch (e) {
        const errorMessages = getErrorMessages(e);
        htmlToast.error(errorMessages);
      } finally {
        endLoading();
      }
    },
    [endLoading, startLoading, targetSubscription, updatePoint]
  );

  const {
    currentNextOrderDeliveryStatus,
    setCurrentNextOrderDeliveryStatus,
    resetToServerNextOrderDeliveryStatus,
    isEditedSchedule,
  } = useDeliveryScheduleValues(targetSubscription);

  // クーポン状況
  const [couponState, setCouponState] = useState({
    coupon: targetSubscription?.coupon ?? "",
    errorCoupon: null as string | null,
  });
  const validateCoupon = useCouponValidations();
  const handleApplyCoupon = useCallback(
    async (coupon: string) => {
      if (!targetSubscription) return;
      try {
        startLoading();
        await validateCoupon({
          coupon,
          isFrozen: temperature === "frozen",
          deliveryDate: currentNextOrderDeliveryStatus.deliveryDate!,
          products: selectedProducts,
          subscription: targetSubscription,
        });
        setCouponState({ coupon, errorCoupon: null });
      } catch (e) {
        if (e instanceof CouponValidationError) {
          if (e.type === CouponValidationErrorType.BeforeToday) {
            htmlToast.error(e.message);
          } else {
            setCouponState({ coupon: "", errorCoupon: coupon });
          }
          return;
        }
        const errorMessages = getErrorMessages(e);
        htmlToast.error(errorMessages);
      } finally {
        endLoading();
      }
    },
    [
      validateCoupon,
      currentNextOrderDeliveryStatus.deliveryDate,
      endLoading,
      temperature,
      selectedProducts,
      startLoading,
      targetSubscription,
    ]
  );

  // 日付などが変わるたびにクーポンをバリデーションする
  useAsync(async () => {
    if (!targetSubscription) return;
    if (!!couponState.coupon || !!couponState.errorCoupon) {
      const nextCoupon = couponState.errorCoupon ?? couponState.coupon;
      try {
        await validateCoupon({
          coupon: nextCoupon,
          isFrozen: temperature === "frozen",
          deliveryDate: currentNextOrderDeliveryStatus.deliveryDate!,
          products: selectedProducts,
          subscription: targetSubscription,
        });
        setCouponState({ coupon: nextCoupon, errorCoupon: null });
      } catch (e) {
        if (e instanceof CouponValidationError) {
          // ページ読込時またはクーポン以外の情報が変更された時、クーポンが期限切れの時はエラー情報を空にする
          // ここで空値をセットしないと、過去のクーポンが設定済の時に「変更を保存」が押せなくなる
          if (e.type === CouponValidationErrorType.BeforeToday) {
            setCouponState({
              coupon: "",
              errorCoupon: null,
            });
            return;
          }
        }
        setCouponState({
          coupon: "",
          errorCoupon: nextCoupon,
        });
      }
    }
  }, [
    couponState.coupon,
    couponState.errorCoupon,
    currentNextOrderDeliveryStatus.deliveryDate,
    selectedProducts,
    targetSubscription,
    temperature,
    validateCoupon,
  ]);

  /**
   * プラン割引の適用状況
   */
  // 配送日におけるプラン割引
  const discountPlanAtDeliveryDate = useMemo(() => {
    if (!discountPlan || !currentNextOrderDeliveryStatus.deliveryDate) return null;
    const deliveryDate = currentNextOrderDeliveryStatus.deliveryDate;
    const endDate = parse(discountPlan.endDate, "yyyy/MM/dd", new Date());
    return isAfter(deliveryDate, endDate) ? null : discountPlan;
  }, [currentNextOrderDeliveryStatus.deliveryDate, discountPlan]);

  /**
   * 選択した商品、ポイント、クーポンを元にカート情報を都度取得
   */
  const {
    currentSubscriptionCart,
    serverSubscriptionCart,
    isEditedSubscriptionCart,
    flushServerSubscriptionCart,
    isFetchingSubscriptionCart,
  } = useSubscriptionCartValues({
    deliveryDate: currentNextOrderDeliveryStatus.deliveryDate,
    nextUsePoint: targetSubscription?.nextUsePoint,
    paymentMethod: targetSubscription?.paymentMethod,
    products: selectedProducts,
    nextUsePointType: pointUsage,
    coupon: couponState.coupon,
    discountPlanName: discountPlanAtDeliveryDate?.discountPlan.discountPlanName,
  });

  const validPurchase =
    ((temperature === "normal"
      ? currentSubscriptionCart?.validPurchase
      : currentSubscriptionCart?.validFreezePurchase) ??
      false) ||
    totalQuantity === 0;

  // 直近のご注文の配送状況
  const { data: latestOrderDeliveryStatus } = useParsedGetOrderWithDeliveryDate(
    targetSubscription?.orders?.at(-1)?.id
  );

  const canChangeOrder = useMemo(
    () =>
      getCanChangeOrder({
        subscription: targetSubscription,
        deliveryDate: latestOrderDeliveryStatus?.deliveryDate
          ? parse(latestOrderDeliveryStatus?.deliveryDate, "yyyy/MM/dd", new Date())
          : undefined,
      }),
    [latestOrderDeliveryStatus?.deliveryDate, targetSubscription]
  );

  // 次の注文の届け予定日
  const nextDeliveryDate = useMemo(
    () =>
      targetSubscription
        ? parse(targetSubscription.nextOrderArrivalDate, "yyyy/MM/dd", new Date())
        : undefined,
    [targetSubscription]
  );

  const updateSubscription = useUpdateSubscriptionSubmit();
  const { mutateAsync: addSubscription } = useParsedAddSubscription();
  const { mutateAsync: deleteSubscription } = useDeleteSubscription();

  // ギフト解約アラート
  const [isGiftCancellationAlertOpen, setIsGiftCancellationAlertOpen] = useState(false);
  const {
    hasNormalGiftsTradesInProcess,
    hasFreezeGiftsTradesInProcess,
    normalGiftsTradesInProcess = [],
    freezeGiftsTradesInProcess = [],
  } = useParsedGetMileGiftsTrades();
  const deliveryGifts = useMemo(() => {
    if (!hasTheOtherSubscription) {
      // 解約の場合
      return [...normalGiftsTradesInProcess, ...freezeGiftsTradesInProcess];
    }

    // 一方の継続コースを解約する場合
    return temperature === "normal" ? normalGiftsTradesInProcess : freezeGiftsTradesInProcess;
  }, [
    freezeGiftsTradesInProcess,
    hasTheOtherSubscription,
    normalGiftsTradesInProcess,
    temperature,
  ]);

  const toggleGiftCancellationAlert = useCallback(() => {
    setIsGiftCancellationAlertOpen((prev) => !prev);
  }, []);

  const discountPlanWarningMessages = useMemo(() => {
    const discountPlan = subscription.customerDiscountPlan;
    if (!currentNextOrderDeliveryStatus.deliveryDate || !discountPlan) return [];
    return getDiscountPlanWarningMessages(
      currentNextOrderDeliveryStatus.deliveryDate,
      format(discountPlan.endDateAsOrderArrivalDate, "yyyy/MM/dd"),
      discountPlan.needToGoalCount,
      discountPlan.discountPlan.planName
    );
  }, [currentNextOrderDeliveryStatus.deliveryDate, subscription.customerDiscountPlan]);
  const newBrandDiscountMessages = useMemo(
    () =>
      currentSubscriptionCart && currentNextOrderDeliveryStatus.deliveryDate
        ? getBrandNewDiscountMessages(
            currentSubscriptionCart,
            currentNextOrderDeliveryStatus.deliveryDate
          )
        : [],
    [currentSubscriptionCart, currentNextOrderDeliveryStatus.deliveryDate]
  );

  const handleDeleteSubscription = useCallback(async () => {
    try {
      startLoading();
      if (!hasTheOtherSubscription) {
        // すべての継続コースを解約する場合
        setIsGiftCancellationAlertOpen(false);
        router.push("/unsubscription");
        return;
      }

      // 一方の継続コースを解約する場合
      targetSubscription &&
        (await deleteSubscription({
          params: { subscription_id: String(targetSubscription.id) },
        }));

      // データを取り直す
      await refetchSubscription();
      flushServerSubscriptionCart();
      setIsGiftCancellationAlertOpen(false);

      const title = temperature === "normal" ? "常温配送" : "冷凍配送";
      toast.success(`${title}の継続コースを解約しました。`);

      router.push("/mypage");
    } catch (e) {
      htmlToast.error(getErrorMessages(e));
    } finally {
      endLoading();
    }
  }, [
    deleteSubscription,
    endLoading,
    hasTheOtherSubscription,
    refetchSubscription,
    flushServerSubscriptionCart,
    router,
    startLoading,
    targetSubscription,
    temperature,
  ]);

  const handleSubmit = useCallback(async () => {
    if (!currentSubscriptionCart) return;
    if (!hasSubscription) {
      // 新規購入はこちら
      const url = buildCheckoutUrl(currentSubscriptionCart.products);
      router.push(url);
      return;
    }

    if (totalQuantity === 0) {
      // 継続コースを解約
      if (
        (temperature === "normal" && hasNormalGiftsTradesInProcess) ||
        (temperature === "frozen" && hasFreezeGiftsTradesInProcess)
      ) {
        // ギフト交換中の場合ワーニングモーダルを表示
        setIsGiftCancellationAlertOpen(true);
      } else {
        // 通常の解約処理
        await handleDeleteSubscription();
      }

      return;
    }

    try {
      startLoading();
      if (!targetSubscription) {
        // 普通商品、冷凍商品のどちらか一方の継続コースを追加する場合
        await addSubscription({ data: currentSubscriptionCart });
        toast.success("継続コースへの追加が完了しました。");
      } else {
        await updateSubscription({
          subscription: {
            ...targetSubscription,
            coupon: currentSubscriptionCart.coupon ?? "",
            nextUsePointType: currentSubscriptionCart.usePointType ?? "none",
            products: currentSubscriptionCart.products,
          },
          deliveryScheduleValues: currentNextOrderDeliveryStatus as DeliveryScheduleFormSchemaValue,
        });
        toast.success("更新が完了しました");
      }

      // サーバーの最新情報をメモリ上に反映
      await refetchSubscription();
      flushServerSubscriptionCart();
    } catch (e) {
      const errorMessages = getErrorMessages(e);
      htmlToast.error(errorMessages);
    } finally {
      endLoading();
    }
  }, [
    currentSubscriptionCart,
    hasSubscription,
    totalQuantity,
    router,
    temperature,
    hasNormalGiftsTradesInProcess,
    hasFreezeGiftsTradesInProcess,
    handleDeleteSubscription,
    startLoading,
    targetSubscription,
    refetchSubscription,
    flushServerSubscriptionCart,
    addSubscription,
    updateSubscription,
    currentNextOrderDeliveryStatus,
    endLoading,
  ]);

  const handleCancel = useCallback(() => {
    setPointUsage(serverSubscriptionCart?.usePointType ?? "none");
    setCouponState({ coupon: serverSubscriptionCart?.coupon ?? "", errorCoupon: null });
    setSelectedProducts(serverSubscriptionCart?.products ?? []);
    resetToServerNextOrderDeliveryStatus();
    setIsExistAdditionalProducts(false);
  }, [serverSubscriptionCart, resetToServerNextOrderDeliveryStatus]);

  const [isOpenSkip, openSkip, closeSkip] = useModalState<SkipModalEvent>();

  const handleSkip = useCallback(async () => {
    const res = await openSkip();
    if (res.type === "cancel") {
      return;
    } else if (res.type === "change-products") {
      scroller.scrollTo(nextProductsSectionId, {
        duration: 500,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    } else if (res.type === "skip") {
      try {
        startLoading();
        const res = await refetchSubscription();
        // defaultScheduleValuesはsubscriptionの変更で更新されるが
        // scheduleValuesは、更新されないため、ここで更新する
        const subscription =
          temperature === "normal" ? res!.subscription! : res!.freezeSubscription!;
        setCurrentNextOrderDeliveryStatus(convertSubscriptionToScheduleValues(subscription));
        flushServerSubscriptionCart();
        toast.success("お届け予定日を更新しました。");
      } catch (e) {
        htmlToast.error(getErrorMessages(e));
      } finally {
        endLoading();
      }
    }
  }, [
    endLoading,
    flushServerSubscriptionCart,
    openSkip,
    refetchSubscription,
    setCurrentNextOrderDeliveryStatus,
    startLoading,
    temperature,
  ]);

  const isEdited = isEditedSchedule || isEditedSubscriptionCart || isExistAdditionalProducts;
  useRegisterDetectDataChangeFunctions(
    `/mypage/${temperature === "normal" ? "subscription" : "freeze_subscription"}`,
    () => isEdited
  );

  return (
    <>
      <LoadingOverlay isLoading={isLoading} className={styles.root}>
        <div className={styles.body}>
          <Column gap={28}>
            {discountPlan && (
              <section>
                <ThreeMonthStatusAccordion discountPlan={discountPlan} />
              </section>
            )}

            <SubscriptionSection
              title="お届け予定日"
              subTitle={temperature === "normal" ? "常温配送" : "冷凍配送"}
              image="https://asset.basefood.co.jp/images/parts/illust_driver.png"
              imageSize={{ width: 80, height: 100 }}
              imageClassName={styles.driverImage}
              edited={isEditedSchedule}
              temperature={temperature}
            >
              <DeliverySchedule
                subscription={targetSubscription}
                onChange={setCurrentNextOrderDeliveryStatus}
                onSkip={!isEditedSchedule ? handleSkip : undefined}
                values={currentNextOrderDeliveryStatus}
                canEdit={canChangeOrder}
                temperature={temperature}
                warningMessages={discountPlanWarningMessages.concat(newBrandDiscountMessages)}
                cartTotalPrice={currentSubscriptionCart?.totalPrice ?? 0}
              />
            </SubscriptionSection>
          </Column>
          <LoadingOverlay isLoading={isFetchingSubscriptionCart}>
            <SubscriptionSection
              id={nextProductsSectionId}
              title="BASE FOOD ボックス"
              subTitle="次回お届けの"
              image="https://asset.basefood.co.jp/images/parts/illust_box.png"
              imageSize={{ width: 91, height: 52 }}
              edited={isEditedSubscriptionCart}
              temperature={temperature}
            >
              <NextDelivery
                subscription={targetSubscription}
                nutrients={
                  temperature === "normal" ? subscription.nutrients : subscription.freezeNutrients
                }
                temperature={temperature}
                cart={currentSubscriptionCart}
                defaultCart={serverSubscriptionCart}
                allProducts={allProducts}
                comebackProducts={comebackProducts}
                deliveryDate={currentNextOrderDeliveryStatus.deliveryDate ?? null}
                onChangeQuantity={canChangeOrder ? handleChangeQuantity : undefined}
                discountPlan={discountPlanAtDeliveryDate}
              />
              <PaymentSummary
                onChangePointUsage={targetSubscription ? handleChangePointUsage : null}
                onApplyCoupon={targetSubscription ? handleApplyCoupon : null}
                cart={currentSubscriptionCart}
                defaultCart={serverSubscriptionCart}
                validPurchase={validPurchase}
                disabledChangeCoupon={!canChangeOrder}
                disabledChangePoint={!canChangeOrder}
                totallyUnsubscribed={totallyUnsubscribed}
                temperature={temperature}
                appliedCoupon={couponState.errorCoupon ?? couponState.coupon}
              />
            </SubscriptionSection>
          </LoadingOverlay>
        </div>

        <Row className={styles.bottomActions}>
          <MyPageSubscriptionBottomActions
            disabled={isFetchingSubscriptionCart}
            cart={currentSubscriptionCart}
            validPurchase={validPurchase}
            canChangeOrder={canChangeOrder}
            isCouponExpired={!!couponState.errorCoupon}
            submitButtonTitle={hasSubscription ? "変更を保存" : "レジに進む"}
            showButtons={isEdited}
            canSubmit={
              (totalQuantity === 0 ||
                (validPurchase && (isEditedSchedule || isEditedSubscriptionCart)) ||
                isExistAdditionalProducts) &&
              !!currentSubscriptionCart
            }
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </Row>

        {targetSubscription && (
          <SkipModal
            open={isOpenSkip}
            temperature={temperature}
            onAction={closeSkip}
            originalDeliveryDate={nextDeliveryDate}
            customer={customer}
            subscription={targetSubscription}
            activeDiscountPlan={subscription.customerDiscountPlan}
          />
        )}
      </LoadingOverlay>

      {/* ギフト解約アラート */}
      <GiftCancellationAlertModal
        open={isGiftCancellationAlertOpen}
        onClose={toggleGiftCancellationAlert}
        onSave={handleDeleteSubscription}
        deliveryGifts={deliveryGifts}
        isLoading={isLoading}
      />
    </>
  );
}
