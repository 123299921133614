"use client";

import { Fragment, useState } from "react";

import clsx from "clsx";
import { format } from "date-fns";

import { Column, Row } from "@/components/containers";
import { Hr } from "@/components/displays";
import Pagination from "@/components/displays/Pagination";
import {
  discountPlanPenaltyDescriptions,
  discountPlanPenaltyStatusLabels,
} from "@/components/domains/3month";
import { useGetCustomerPenaltyPayments } from "@/generated/open-api/customer/customer";
import { PenaltyPayment } from "@/generated/open-api/schemas";
import { numberFormat } from "@/utils";
import { convertTimeStampToDate } from "@/utils/converters";

import { HistoryDataLoadingBody } from "../HistoryDataLoadingBody";
import { PCHistoryTableWrapper } from "../PCHistoryTableWrapper";
import { SPHistoryTableWrapper } from "../SPHistoryTableWrapper";
import styles from "./PenaltyPaymentTable.module.scss";

type ResponsiveProps<T> = T | { sp: T; pc: T };

interface TableColumn<T> {
  field: string;
  title: string;
  bold?: ResponsiveProps<boolean>;
  nowrap?: ResponsiveProps<boolean>;
  align?: ResponsiveProps<"left" | "center" | "right">;
  render?: (value: T) => React.ReactNode;
}

const getResponsiveValue = <T,>(
  prop: ResponsiveProps<T> | undefined,
  breakpoint: "sp" | "pc"
): T | undefined => {
  if (prop === undefined) return undefined;
  if (typeof prop === "object" && prop !== null && "sp" in prop) {
    return prop[breakpoint];
  }
  return prop;
};

const tableColumns: TableColumn<PenaltyPayment>[] = [
  {
    field: "id",
    title: "請求番号",
    nowrap: { sp: false, pc: true },
  },
  {
    field: "invoiced_at",
    title: "請求日",
    nowrap: true,
    render: (penaltyPayment) => {
      return penaltyPayment.invoiced_at
        ? format(convertTimeStampToDate(penaltyPayment.invoiced_at), "yyyy/MM/dd")
        : "-";
    },
  },
  {
    field: "status",
    title: "支払い状況",
    bold: true,
    nowrap: true,
    render: (penaltyPayment) => {
      return discountPlanPenaltyStatusLabels[penaltyPayment.status];
    },
  },
  {
    field: "amount",
    title: "支払い金額",
    bold: true,
    nowrap: true,
    render: (penaltyPayment) => {
      return `¥${numberFormat(penaltyPayment.penalty_paid_amount)}`;
    },
  },
  {
    field: "description",
    title: "詳細",
    align: { sp: "right", pc: "left" },
    render: (penaltyPayment) => {
      return discountPlanPenaltyDescriptions[penaltyPayment.discount_plan_name] || "-";
    },
  },
];

export function PenaltyPaymentTable(): React.ReactNode {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetCustomerPenaltyPayments({ page });
  const payments = data?.data || [];

  return (
    <>
      {/* PC版 */}
      <PCHistoryTableWrapper>
        <table className="text__center">
          <thead>
            <tr className="text__s">
              {tableColumns.map((column) => (
                <th key={column.field} className={styles.pcTableTitle}>
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading && <HistoryDataLoadingBody colSpan={5} />}
            {payments.map((payment) => (
              <tr key={payment.id}>
                {tableColumns.map((column) => {
                  const value = column.render
                    ? column.render(payment)
                    : (payment[column.field as keyof PenaltyPayment] as React.ReactNode);
                  const pcAlign = getResponsiveValue(column.align, "pc");
                  const pcBold = getResponsiveValue(column.bold, "pc");
                  const pcNowrap = getResponsiveValue(column.nowrap, "pc");
                  return (
                    <td key={column.field} className={`text__${pcAlign || "center"}`}>
                      <p className={clsx(pcBold && "text__bold", pcNowrap && "wsnr")}>{value}</p>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination onPageChange={setPage} totalPage={data?.meta?.last_page || 0} />
      </PCHistoryTableWrapper>

      {/* SP版 */}
      <SPHistoryTableWrapper>
        {payments.map((payment) => (
          <Fragment key={payment.id}>
            <div className="pd__top__m">
              <Column className="mg__bottom__m pd__top__xs" gap={10}>
                {tableColumns.map((column) => {
                  const value = column.render
                    ? column.render(payment)
                    : (payment[column.field as keyof PenaltyPayment] as React.ReactNode);
                  const spAlign = getResponsiveValue(column.align, "sp");
                  const spBold = getResponsiveValue(column.bold, "sp");
                  const spNowrap = getResponsiveValue(column.nowrap, "sp");
                  return (
                    <Row key={column.field} align="start" justify="space-between">
                      <p
                        className={clsx("wsnr", styles.spTableTitle, `text__${spAlign || "right"}`)}
                      >
                        {column.title}：
                      </p>
                      <p
                        className={clsx("text__right", spBold && "text__bold", spNowrap && "wsnr")}
                      >
                        {value}
                      </p>
                    </Row>
                  );
                })}
              </Column>
            </div>
            <Hr noMargin />
          </Fragment>
        ))}
        <Pagination onPageChange={setPage} totalPage={data?.meta?.last_page || 0} />
      </SPHistoryTableWrapper>
    </>
  );
}
