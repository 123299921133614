import { LinkButton } from "@/components/buttons";
import { ImageLegacy } from "@/components/displays";

export function ChangeDeliveryDateSection(): React.ReactNode {
  return (
    <section>
      <div className="col-12 col-m-10 col-offset-m-1">
        <p className="text__xl text__bold text__center pd__top__l">
          継続コース
          <span className="text__l">の</span>
          お届け日
          <span className="text__l">が</span>
          <br />
          <span className="text__red">いつでも変更可能！</span>
        </p>
        <p className="text__m text__left text__center__pc mg__top__m pd__bottom__m">
          お客さまのペースに合わせてお届け日を自由に変更＆スキップすることが可能です。
          <br className="clear__sp" />
          <span className="text__red text__bold">最大59日先</span>
          までご調整が可能です。
        </p>
      </div>
      <div className="col-12 col-m-6 col-offset-m-3">
        <ImageLegacy
          src="https://asset.basefood.co.jp/images/parts/illust_deliveryman_box.png"
          alt="最大59日先までご調整が可能です。"
          aspectRatio="476/317"
        />
        <div className="text__center mg__top__m">
          <LinkButton
            href="/mypage#schedule"
            color="yellow"
            data-testid="ChangeDeliveryDateSection-skip-button"
          >
            次回のお届けをスキップする
          </LinkButton>
        </div>
        <div className="text__center mg__top__m mg__bottom__l">
          <LinkButton
            href="/mypage#schedule"
            color="yellow"
            data-testid="ChangeDeliveryDateSection-change-delivery-date-button"
          >
            お届け日を変更する
          </LinkButton>
        </div>
      </div>
    </section>
  );
}
