import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { ImageLegacy } from "@/components/displays";

import styles from "./SubscriptionSection.module.scss";
import { Temperature } from "../types";

interface SubscriptionSectionProps {
  id?: string;
  temperature: Temperature;
  title: string;
  subTitle: string;
  edited: boolean;
  image: string;
  imageSize: { width: number; height: number };
  imageClassName?: string;
  children: React.ReactNode;
}

export function SubscriptionSection({
  id,
  temperature,
  title,
  subTitle,
  edited,
  image,
  imageSize,
  imageClassName,
  children,
}: SubscriptionSectionProps): React.ReactNode {
  return (
    <section id={id} className={clsx(styles.root)}>
      <Column className={clsx(styles.container, edited && styles.edited)}>
        <Row className={styles[`${temperature}Header`]}>
          <Column className={styles.titleContainer}>
            <h5 className={clsx("text__m", "text__bold")}>{subTitle}</h5>
            <h4 className={clsx("text__l", "text__bold")}>{title}</h4>
          </Column>
          <ImageLegacy alt={title} size={imageSize} src={image} className={imageClassName} />
        </Row>
        <Column className={clsx(styles.body)}>{children}</Column>
      </Column>
    </section>
  );
}
