import { ProductModel } from "@/models/product/type";

/**
 * @see https://bf-partner.slack.com/archives/CPKSLHN20/p1721809403355619?thread_ts=1721803801.624849&cid=CPKSLHN20
 *
 * 存在する理由：
 * 新規の場合、選択肢にあまりに多い数が出てくると見栄えが悪いので、productsテーブルに持っている値で制御しています。
 * 一方で、既存の場合は見栄えもそこまで気にする必要はなく大量購入する方も実際にいらっしゃるので、大きな数字の選択肢を増やしている
 *
 */
export const makeQuantityOptions = (product: ProductModel) => {
  let options: number[] = [];
  if (product.selectOptions[product.selectOptions.length - 1] < 40) {
    // Note.パスタソースなど、「個別画面での選択可能数40個に満たない商品」はマイページでもそれ以上追加できないようにしている
    // おそらく倉庫での在庫保管量を考慮して制限を設けている
    // (個別画面から選択可能数を超えてカートに入れることは可能であり、その場合どの商品でも40を超えるので、仕様確認後修正対象)
    options = product.selectOptions.sort((a, b) => a - b);
  } else {
    const additionalOptions = [50, 60, 80, 100, 120];
    options = Array.from(new Set([...product.selectOptions, ...additionalOptions])).sort(
      (a, b) => a - b
    );
  }
  const quantity = product.quantity;
  if (quantity && options.indexOf(quantity) === -1) {
    options.push(quantity);
  }
  return options;
};
