import { useMemo } from "react";

import clsx from "clsx";
import Link from "next/link";

import { ImageLegacy } from "@/components/displays";
import { OrderWithDeliveryDateDeliveryStatus } from "@/generated/open-api/schemas";
import { orderDeliveryStatusLabels } from "@/models/orderWithDeliveryDate/consts";
import { OrderWithDeliveryDateModel } from "@/models/orderWithDeliveryDate/type";
import {
  frozenTemperatureProductsNames,
  normalTemperatureProductsNames,
} from "@/models/product/consts";
import { ProductTemperature } from "@/models/product/type";
import { SubscriptionModel } from "@/models/subscription/type";
import { useGetParsedProductsByNames } from "@/queries";
import { getWeekDay } from "@/utils";
import { appendProductNameByVariantId, sortProducts } from "@/utils/products";

import styles from "./DeliveryScheduleDetail.module.scss";
import {
  DeliveryDate,
  extractMonthAndDateString,
  extractMonthAndDateStringWithWeekDay,
  isLastOrderDelivered,
} from "../../helpers";

interface DeliveryScheduleDetailProps {
  temperature: ProductTemperature;
  subscription: SubscriptionModel;
  /**
   * subscription.ordersが空の場合、undefined
   */
  deliveryDate?: DeliveryDate;
  orderWithDeliveryDate?: OrderWithDeliveryDateModel;
}

const DELIVERY_STATUS_COLOR = {
  [OrderWithDeliveryDateDeliveryStatus.Shipped]: "text__green",
  [OrderWithDeliveryDateDeliveryStatus.PartlyShipped]: "text__red",
  [OrderWithDeliveryDateDeliveryStatus.Cancel]: "text__red",
  [OrderWithDeliveryDateDeliveryStatus.None]: "text__yellow",
};

export function DeliveryScheduleDetail({
  temperature,
  subscription,
  deliveryDate,
  orderWithDeliveryDate,
}: DeliveryScheduleDetailProps): React.ReactNode {
  const showLatestAndNextOrder = useMemo(
    () =>
      subscription.orderIds.length > 0 &&
      !isLastOrderDelivered(subscription, orderWithDeliveryDate),
    [orderWithDeliveryDate, subscription]
  );

  const lastOrderId = useMemo(() => {
    const orders = subscription.orders;
    return orders[orders.length - 1]?.id;
  }, [subscription]);

  const deliveryStatusLabel = useMemo(() => {
    if (!orderWithDeliveryDate?.deliveryStatus) return orderDeliveryStatusLabels["None"];
    return orderDeliveryStatusLabels[orderWithDeliveryDate.deliveryStatus];
  }, [orderWithDeliveryDate]);

  const deadlineDate = useMemo(() => {
    return extractMonthAndDateStringWithWeekDay(subscription.nextOrderChangeDeadlineDate);
  }, [subscription]);

  const lastDeliveryDate = useMemo(() => {
    return (
      deliveryDate?.dateWithWeekDay &&
      extractMonthAndDateStringWithWeekDay(deliveryDate.dateWithWeekDay)
    );
  }, [deliveryDate]);

  const nextOrderArrivalDate = useMemo(() => {
    return `${extractMonthAndDateString(subscription.nextOrderArrivalDate)}(${getWeekDay(subscription.nextOrderArrivalDate)})`;
  }, [subscription]);

  const statusColor = useMemo(() => {
    return DELIVERY_STATUS_COLOR[
      orderWithDeliveryDate?.deliveryStatus ?? OrderWithDeliveryDateDeliveryStatus.None
    ];
  }, [orderWithDeliveryDate?.deliveryStatus]);

  const productNames =
    temperature === "normal" ? normalTemperatureProductsNames : frozenTemperatureProductsNames;
  const { data: allProducts } = useGetParsedProductsByNames({ names: [...productNames] });

  const products = useMemo(() => {
    const productsWithName = subscription.products.map((p) =>
      appendProductNameByVariantId(p, allProducts)
    );
    return sortProducts(productsWithName).map((product) => {
      return {
        image: product.images[0],
        quantity: product.quantity,
        variantTitle: product.variantTitle,
      };
    });
  }, [allProducts, subscription.products]);

  return (
    <div id="delivery_schedule_component" className={styles.subscriptionDate}>
      <div className={clsx("bg__white mg__bottom__s", styles.subscriptionLink)}>
        {temperature === "normal" ? (
          <span
            className={clsx("tag inline yellow text__white mini square", styles.subscriptionTag)}
          >
            常温配送
          </span>
        ) : (
          <span
            className={clsx("tag inline darkblue text__white mini square", styles.subscriptionTag)}
          >
            冷凍配送
          </span>
        )}
        {showLatestAndNextOrder && deliveryDate ? (
          <>
            <span className={clsx("text__m text__bold", statusColor)}>{deliveryStatusLabel}</span>
            <div className="pd__bottom__m">
              <p className="text__m mg__top__xs mg__bottom__s">
                <span className="text__bold">
                  {lastDeliveryDate}
                  {deliveryDate.timezoneSuffix}
                </span>
                {/*スペースを入れている*/}
                {` ${deliveryDate.suffix}`}
              </p>

              <Link
                className={clsx("text__s", styles.orderDetailLink)}
                href={`/mypage/order_detail?order_id=${lastOrderId}`}
              >
                ご注文の詳細を見る
              </Link>
            </div>
          </>
        ) : (
          <span className="text__m text__bold text__success">変更{deadlineDate}まで</span>
        )}

        <div className={showLatestAndNextOrder ? clsx("bg__gray", styles.revocableOrder) : ""}>
          <p className="text__m mg__top__xs">
            <span className="text__bold">
              {nextOrderArrivalDate}
              {subscription.calcNextOrderArrivalTimezoneStr}
            </span>
            {/*スペースを入れている*/}
            {` ${deliveryDate?.suffix ?? "にお届け予定"}`}
          </p>

          {/* 商品がない場合は休眠扱い */}
          {products.length === 0 && (
            <p className="text__red mg__top__s">商品が選択されていません。</p>
          )}

          <div className={clsx("mg__top__s mg__bottom__m", styles.productsContainer)}>
            <ul className={styles.products}>
              {products.map((product) => (
                <li className={styles.product} key={product.variantTitle}>
                  <ImageLegacy
                    src={product.image}
                    alt={product.variantTitle}
                    size={{ width: 55, height: 55 }}
                  />
                  <span
                    className={clsx(
                      "text__s text__bold text__white text__center",
                      styles.productCount
                    )}
                  >
                    {product.quantity}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          <div className="text__center">
            <Link
              href={
                temperature === "normal" ? "/mypage/subscription" : "/mypage/freeze_subscription"
              }
              className={clsx(
                "btn inline round angle__right wsnr",
                showLatestAndNextOrder ? "bg__white" : "bg__gray"
              )}
              data-testid={`DeliveryScheduleDetail-${temperature}-change-order-button`}
            >
              次回お届け日・内容を変更する<i className="fas fa-angle-right"></i>
            </Link>
          </div>
          {subscription.isOrderFixDateChangedByInventry && (
            <p className="text__s text__red mg__top__s">
              お届け予定日に
              {subscription.nextOrderArrivalDate}
              をご指定の場合は、出荷作業の都合上、変更期限をご注文の6日前とさせていただいております。何卒ご了承ください。
            </p>
          )}
          {temperature === "normal" && (
            <p className="text__s text__red mg__top__s">
              ※BASE YAKISOBA
              塩焼きそばはご注文多数のため配送遅延が発生しています。何とぞご了承ください。
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
