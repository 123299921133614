import clsx from "clsx";
import Link from "next/link";

import styles from "./ReturnToMyPageLink.module.scss";

interface ReturnToMyPageLinkProps {
  "data-testid": string;
}

export function ReturnToMyPageLink({
  "data-testid": testId,
}: ReturnToMyPageLinkProps): React.ReactNode {
  return (
    <div className="text__center mg__bottom__l">
      <Link className={clsx("text__s", styles.returnLink)} href="/mypage" data-testid={testId}>
        解約せずにマイページに戻る
      </Link>
    </div>
  );
}
