"use client";
import Link from "next/link";

import { ImageLegacy } from "@/components/displays";
import { withCsr } from "@/utils";

import bnr from "$/images/banner/bnr_shioyakisoba_sp.jpg";

import styles from "./Announcements.module.scss";
import { useImageLinks } from "./helpers";
import { ImageLinkList } from "../ImageLinkList";

export const Announcements = withCsr(function Announcements(): React.ReactNode {
  const { imageLinks } = useImageLinks();

  return (
    <div>
      <ImageLinkList images={imageLinks} cols={2} flow={{ sp: "column", pc: "row" }} />
      <div className="pd__bottom__s">
        <Link href="/products/deli/baseyakisoba/shioyakisoba" className={styles.link}>
          <ImageLegacy
            src={bnr}
            alt="BASE YAKISOBA 塩焼きそば 新発売"
            containerClassName={styles.imageContainer}
            className={styles.image}
          />
        </Link>
      </div>
    </div>
  );
});
